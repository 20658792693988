import { AgGridReact } from "@ag-grid-community/react";
import { useEffect, useMemo, useRef, useState } from "react";
import categoryColumns from "../tableStructures/categoryColumns";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategoriesData, setCategoryLoading, setLoadUpdateCategory, updateCategoryChanges } from "../../actions/ticketActions";
import AddIcon from '@mui/icons-material/Add';
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CategoryModal from "./CategoryModal";
import DataSaverOnOutlinedIcon from '@mui/icons-material/DataSaverOnOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { Box } from "@mui/material";
const isMobile = window.innerWidth <= 768;


const CategoryBoard = () => {
    const [gridApi, setGridApi] = useState(null);
    const [gridHeight, setGridHeight] = useState("");
    const [rowData, setRowData] = useState([]);
    const [quickFilterText, setQuickFilterText] = useState("");
    const [dirtyFields, setDirtyFields] = useState([]);
    const [isCategoryModal, setIsCategoryModal] = useState(false);
    const rowHeight = isMobile ? 42 : 80;
    const { categoriesData, assignedToPersonMap, groupEmployeData, loadUpdateCategory, categoryLoading } = useSelector(state => state.tickets);
    const gridRef = useRef();
    const dispatch = useDispatch();
    const gridStyle = useMemo(
        () => ({
            height: rowData?.length > 0 ? gridHeight : "300px",
            maxHeight: isMobile ? gridHeight : "700px",
            width: "100%",
            // borderLeft: `2px solid ${colorcode}`,
            backgroundColor: isMobile ? "none" : "#171717",
            overflowX: "auto",
        }),
        [gridHeight, rowData, categoriesData, quickFilterText]
    );
    const containerStyle = useMemo(
        () => ({
            width: "100%",
            height: "auto",
            minHeight: "200px",
        }),
        []
    );
    useEffect(() => {
        if (categoriesData?.data?.length > 0) {
            setRowData(categoriesData?.data?.map((item) => {
                return {
                    ...item,
                    "plant_manager": assignedToPersonMap[item?.plant_manager] + ` (${item?.plant_manager})`,
                    "emp_list": groupEmployeData.filter(item => item.type === "employee")
                }
            }))
        }
    }, [categoriesData]);
    const onGridReady = (params) => {
        setGridApi(params.api);
        gridRef.current = params.api;
        setTimeout(() => {
            params.api.onFilterChanged();
        }, 500);
        params.api.onFilterChanged();
    };

    useEffect(() => {
        dispatch(setCategoryLoading(true));
        dispatch(fetchCategoriesData());
    }, [])

    const onCellValueChanged = (params) => {
        const { colDef, oldValue, newValue, data } = params;

        if (oldValue !== newValue) {
            setDirtyFields(
                (prevDirtyFields) => {
                    const index = prevDirtyFields.findIndex((field) => field.CategoryID === data.CategoryID);
                    if (index !== -1) {
                        // Update the existing object at the found index
                        const updatedFields = [...prevDirtyFields];
                        updatedFields[index] = {
                            ...updatedFields[index],
                            [colDef.field]: colDef.field === "plant_manager" ? newValue.match(/\((.*?)\)/)[1] : newValue,
                        };
                        return updatedFields;
                    } else {
                        // Add a new entry if no match is found
                        return [
                            ...prevDirtyFields,
                            { CategoryID: data.CategoryID, [colDef.field]: colDef.field === "plant_manager" ? newValue.match(/\((.*?)\)/)[1] : newValue },
                        ];
                    }
                }
            );
        }
    };

    useEffect(() => {
        if (gridApi) {
            gridApi.setGridOption("quickFilterText", quickFilterText);
            gridApi.onFilterChanged();
            const displayedRows = [];
            gridApi?.forEachNodeAfterFilterAndSort((node) => {
                displayedRows.push(node.data);
            });
            const rowCount = displayedRows.length;
            const calculatedHeight = (rowCount) * (rowHeight);
            setGridHeight(`${calculatedHeight}px`);
        }
    }, [gridApi, categoryColumns, isMobile, rowData, quickFilterText, categoriesData]);
    const handleAddCategory = () => {
        setIsCategoryModal(true);
    }
    const handleCategoryClose = () => {
        setIsCategoryModal(false)
    }
    const handleUpdateCategory = () => {
        dispatch(setLoadUpdateCategory(true));
        dispatch(updateCategoryChanges(dirtyFields));
    }
    return (
        <>
            <div className="table_main">
                <div className="action_container">
                    <div className="search_container_category">
                        <input type="text" placeholder="Search..." onChange={(e) => setQuickFilterText(e.target.value)} className="searchbar_category"/>
                        <SearchOutlinedIcon className="search_icon" />
                    </div>
                    <div className="btn_div">
                        <button className="create_btn"><div className="btn_content" onClick={handleUpdateCategory}><DataSaverOnOutlinedIcon className="add_icon" loading /><span className="btn_text">Save Changes</span></div></button>
                        <button className="create_btn"><div className="btn_content" onClick={handleAddCategory}><AddIcon className="add_icon" /> <span className="btn_text">Add Category</span></div></button>
                    </div>
                </div>
                <div className={`ag-theme-quartz-dark`} style={containerStyle}>
                    <div style={gridStyle}>
                        <AgGridReact
                            ref={gridRef}
                            rowData={rowData}
                            columnDefs={categoryColumns}
                            defaultColDef={{
                                resizable: true,
                                sortable: true,
                                autoSize: true,
                                filter: true,
                                flex: 1,
                                minWidth: 100,
                                // cellStyle: { backgroundColor: "#272727", color: "white" },
                                headerClass: "custom-header",
                                width: "100%",
                            }}
                            // headerHeight={window.innerWidth <= 768 ? 0 : 40}
                            domLayout={Number(gridHeight.slice(0, -2)) < 800 ? "autoHeight" : "normal"}
                            rowSelection="multiple"
                            onGridReady={onGridReady}
                            suppressServerSideFullWidthLoadingRow={false}
                            // rowHeight={isMobile ? "80px" : "42px"}
                            onCellValueChanged={onCellValueChanged}
                            loadingOverlayComponent={() => (
                                categoryLoading ? <Box sx={{ display: "flex", height: "800px", justifyContent: "center", alignItems: "center" }}>
                                    <CircularProgress />
                                </Box> :
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                        backgroundColor: "#272727",
                                        color: "white",
                                        width: "100%"
                                    }}>
                                        No data found
                                    </div>
                            )}
                        />
                    </div>
                </div>
            </div>
             {/* <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={loadUpdateCategory}
            >
                <CircularProgress color="inherit" />
            </Backdrop> */}
            <CategoryModal isCategoryModal={isCategoryModal} handleCategoryClose={handleCategoryClose} />
        </>
    );
}
export default CategoryBoard;