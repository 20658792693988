import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CustomHeader from "../dashboard/CustomHeader";
import TitleIcon from "@mui/icons-material/Title";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import StraightenIcon from "@mui/icons-material/Straighten";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import formatDateToDDMMYYYY from "../dashboard/formatDateToDDMMYYYY";

const isMobile = window.innerWidth <= 768;

const columnDefs = [
    {
        field: "TicketID",
        headerComponent: CustomHeader,
        headerComponentParams: { icon: <InfoOutlinedIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Ticket ID" },
        filter: "agNumberColumnFilter",
        minWidth: isMobile ? 40 : 200,
        filterParams: {
            defaultToNothingSelected: true,
            // default: "Urgent",  
        },
        pinned: true,
        cellRenderer: (params) => {
            const valueArray = params?.value;

            return (
                <div style={{ display: "flex", alignItems: "center" }}>
                    {params.data.notification_status == false && <PriorityHighIcon sx={{ color: "#ef6e35" }} />}
                    <span>{valueArray}</span>
                </div>
            );
        },
        cellStyle: (params) => {
            const borderColor =
                params.data.PriorityID === "Urgent"
                    ? "red"
                    : params.data.PriorityID === "Normal"
                        ? "white"
                        : "green";
            return {
                borderLeft: `2px solid ${borderColor}`,
                backgroundColor: params.data.notification_status == false ? "#222222" : "#272727",
                color: "white",
            };
        },
        hide: isMobile,
    },
    {
        field: "Title",
        headerComponent: isMobile ? null : CustomHeader,
        headerComponentParams: isMobile ? null : { icon: <TitleIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Title" },
        filter: isMobile ? "" : "agSetColumnFilter",
        minWidth: isMobile ? 300 : 200,
        filterParams: {
            defaultToNothingSelected: true,  // Ensures no default is selected at start
            // default: "Urgent",  
        },
        cellRenderer: (params) => {
            const valueArray = params?.value;
            // if (!valueArray || valueArray.length !== 2) {
            //     return null;  // If value is not in the expected format, return nothing
            // }

            const [ticketID, title, assignedTo, ticketStatus] = valueArray; // Destructure the value array
            if (!isMobile) return title;
            return (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Grid3x3Icon sx={{ height: "20px", width: "20px" }} />
                            <span style={{ marginLeft: "5px", fontSize: "14px" }}>{ticketID}</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <PersonIcon sx={{ height: "25px", width: "20px" }} />
                            <span style={{ marginLeft: "5px", fontSize: "14px" }}>{assignedTo}</span>
                        </div>
                    </div>
                    <div style={{ display: "flex", marginTop: "-8px", justifyContent: "space-between", width: "100%" }}> {/* Using a div to render on a new line */}
                        <span style={{ fontSize: "14px", maxWidth: "200px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{title}</span>
                        <span style={{ marginLeft: "5px", fontSize: "12px" }}>{ticketStatus}</span>
                    </div>
                </div>
            );
        },
        cellStyle: (params) => {
            return {
                backgroundColor: params.data.notification_status == false ? "#222222" : "#272727",
                color: "white",
            };
        },
    },
    // {
    //     field: "WorkTypeID",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <SettingsApplicationsOutlinedIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Work Type" },
    //     filter: "agSetColumnFilter",
    //     minWidth: 200,
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //         // default: "Urgent",  
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    {
        field: "PriorityID",
        headerComponent: CustomHeader,
        headerComponentParams: { icon: <PriorityHighIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Priority" },
        filter: "agSetColumnFilter",
        minWidth: 200,
        filterParams: {
            defaultToNothingSelected: true,
            default: ["Urgent"],
        },
        hide: isMobile,
        cellStyle: (params) => {
            return {
                backgroundColor: params.data.notification_status == false ? "#222222" : "#272727",
                color: "white",
            };
        },
    },
    // {
    //     field: "CategoryID",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <ClassOutlinedIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Category" },
    //     filter: "agSetColumnFilter",
    //     minWidth: 200,
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    {
        field: "AssignedToPersonID",
        headerComponent: CustomHeader,
        headerComponentParams: { icon: <AccountCircleOutlinedIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Assigned To" },
        filter: "agSetColumnFilter",
        minWidth: 200,
        filterParams: {
            defaultToNothingSelected: true,  // Ensures no default is selected at start
        },
        cellRenderer: (params) => {
            const valueArray = params?.value;
            // if (!valueArray || valueArray.length !== 2) {
            //     return null;  // If value is not in the expected format, return nothing
            // }

            const [value, type, assignedToEmail] = valueArray; // Destructure the value array
            if (type === "Person") {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <PersonIcon /> <span style={{ marginLeft: "5px" }}>{value}</span>
                    </div>
                );
            } else if (type === "Group") {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <GroupIcon /> <span style={{ marginLeft: "5px" }}>{value}</span>
                    </div>
                );
            }
            return null;
        },
        hide: isMobile,
        cellStyle: (params) => {
            return {
                backgroundColor: params.data.notification_status == false ? "#222222" : "#272727",
                color: "white",
            };
        },
    },
    {
        field: "TicketStatusID",
        headerComponent: CustomHeader,
        headerComponentParams: { icon: <EventAvailableOutlinedIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Status" },
        filter: "agSetColumnFilter",
        minWidth: 200,
        filterParams: {
            defaultToNothingSelected: false,
        },
        hide: isMobile,
        cellStyle: (params) => {
            return {
                backgroundColor: params.data.notification_status == false ? "#222222" : "#272727",
                color: "white",
            };
        },
    },
    // {
    //     field: "Description",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: {
    //         icon: <TextBgWithAttachment />,
    //         displayName: "Description"
    //     },
    //     cellRenderer: (params) => {
    //         return (
    //             <div className="description_cell">
    //                 <p
    //                     dangerouslySetInnerHTML={{ __html: params.value }}
    //                 />
    //             </div>
    //         );
    //     },
    //     filter: "agSetColumnFilter",
    //     minWidth: 200,
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //         // default: "Urgent",  
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    // {
    //     field: "CustomerName",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <TextWithBackground />, displayName: "Customer Name" },
    //     filter: "agSetColumnFilter",
    //     minWidth: 200,
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //         // default: "Urgent",  
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    // {
    //     field: "AssignedPartNumber",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <LinkIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Assigned Part Numbers" },
    //     cellRenderer: (params) => {
    //         const value = params?.value;
    //         // Ensure value exists and is a string
    //         if (typeof value === "string") {
    //             const itemList = value.split(", ");
    //             return (
    //                 <>
    //                     {itemList?.map((item, index) => (
    //                         <OemText key={index} value={item.trim()} />
    //                     ))}
    //                 </>
    //             );
    //         }
    //         return null;
    //     },
    //     filter: "agSetColumnFilter",
    //     minWidth: 200,
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //         // default: "Urgent",  
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    // {
    //     field: "QuotePartRequired",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <FormatListNumberedIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Quote Parts Required" },
    //     filter: "agNumberColumnFilter",
    //     minWidth: 200,
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //         // default: "Urgent",  
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    // {
    //     field: "OrderPartRequired",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <PlaylistAddCheckOutlinedIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Order Parts Required" },
    //     filter: "agNumberColumnFilter",
    //     minWidth: 200,
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //         // default: "Urgent",  
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    // {
    //     field: "WordSpecsRequired",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <AccessTimeIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Word Specs Required" },
    //     filter: "agNumberColumnFilter",
    //     minWidth: 200,
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //         // default: "Urgent",  
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    // {
    //     field: "CADDrawingsRequired",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <StraightenIcon sx={{ height: "18px", width: "18px" }} />, displayName: "CAD Drawings Required" },
    //     filter: "agNumberColumnFilter",
    //     minWidth: 200,
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //         // default: "Urgent",  
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    // {
    //     field: "ReasonForLate",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <AccessTimeIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Reason For Late" },
    //     filter: "agSetColumnFilter",
    //     minWidth: 200,
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //         // default: "Urgent",  
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    // {
    //     field: "Notes",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <CheckCircleOutlineIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Notes" },
    //     filter: "agSetColumnFilter",
    //     minWidth: 200,
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //         // default: "Urgent",  
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    // {
    //     field: "ReasonForNoQuoteID",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <CheckCircleOutlineIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Reason For No Quote" },
    //     filter: "agSetColumnFilter",
    //     minWidth: 200,
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //         // default: "Urgent",  
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    // {
    //     field: "MistakeCodeID",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <CheckCircleOutlineIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Mistake Code" },
    //     filter: "agSetColumnFilter",
    //     minWidth: 200,
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //         // default: "Urgent",  
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    // {
    //     field: "ModifiedDateTime",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <CalendarMonthIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Last Modified Date" },
    //     minWidth: 200,
    //     filter: "agDateColumnFilter",
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //         // default: "Urgent",  
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    {
        field: "CreatedBy",
        headerComponent: CustomHeader,
        headerComponentParams: { icon: <CheckCircleOutlineIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Created By" },
        minWidth: 200,
        filter: "agSetColumnFilter",
        filterParams: {
            defaultToNothingSelected: true,  // Ensures no default is selected at start
            // default: [1],    // Replace "SomeDefaultValue" with the value you want
        },
        hide: isMobile,
        cellStyle: (params) => {
            return {
                backgroundColor: params.data.notification_status == false ? "#222222" : "#272727",
                color: "white",
            };
        },
    },
    {
        field: "Price",
        headerComponent: CustomHeader,
        headerComponentParams: { icon: <CheckCircleOutlineIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Price" },
        filter: "agSetColumnFilter",
        minWidth: 200,
        filterParams: {
            defaultToNothingSelected: true,  // Ensures no default is selected at start
            // default: "Urgent",  
        },
        hide: isMobile,
        cellStyle: (params) => {
            return {
                backgroundColor: params.data.notification_status == false ? "#222222" : "#272727",
                color: "white",
            };
        },
    },
    {
        field: "Footage",
        headerComponent: CustomHeader,
        headerComponentParams: { icon: <StraightenIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Footage" },
        filter: "agNumberColumnFilter",
        minWidth: 200,
        filterParams: {
            defaultToNothingSelected: true,  // Ensures no default is selected at start
            // default: "Urgent",  
        },
        hide: isMobile,
        cellStyle: (params) => {
            return {
                backgroundColor: params.data.notification_status == false ? "#222222" : "#272727",
                color: "white",
            };
        },
    },
    // {
    //     field: "Creator",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <CheckCircleOutlineIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Creator" },
    //     filter: "agSetColumnFilter",
    //     minWidth: 200,
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //         // default: "Urgent",  
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    // {
    //     field: "LastModifiedByID",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <CheckCircleOutlineIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Last Modified By" },
    //     filter: "agSetColumnFilter",
    //     minWidth: 200,
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //         // default: "Urgent",  
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    // {
    //     field: "DateStatusChange",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <CalendarMonthIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Date Status Change" },
    //     filter: "agDateColumnFilter",
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    {
        field: "EstdTimeOfCompletionOriginal",
        headerComponent: CustomHeader,
        headerComponentParams: { icon: <AccessTimeIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Estimated Time of Completion (Original)" },
        minWidth: 200,
        filter: "agDateColumnFilter",
        filterParams: {
            defaultToNothingSelected: true,  // Ensures no default is selected at start
            // default: "Urgent",  
        },
        hide: isMobile,
        cellStyle: (params) => {
            return {
                backgroundColor: params.data.notification_status == false ? "#222222" : "#272727",
                color: "white",
            };
        },
    },
    {
        field: "EstdTimeOfCompletionCurrent",
        headerComponent: CustomHeader,
        headerComponentParams: { icon: <AccessTimeIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Estimated Time of Completion (Current)" },
        minWidth: 200,
        filter: "agDateColumnFilter",
        filterParams: {
            defaultToNothingSelected: true,  // Ensures no default is selected at start
            // default: "Urgent",  
        },
        hide: isMobile,
        cellStyle: (params) => {
            return {
                backgroundColor: params.data.notification_status == false ? "#222222" : "#272727",
                color: "white",
            };
        },
    },
    {
        field: "CreatedTimestamp",
        headerComponent: CustomHeader,
        headerComponentParams: { icon: <CalendarMonthIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Created" },
        minWidth: 200,
        filter: "agDateColumnFilter",
        filterParams: {
            defaultToNothingSelected: true,  // Ensures no default is selected at start
            // default: "Urgent",  
        },
        hide: isMobile,
        cellStyle: (params) => {
            return {
                backgroundColor: params.data.notification_status === false ? "#222222" : "#272727",
                color: "white",
            };
        },
        cellRenderer: (params) => {
            const value = params?.value;
            return (
                formatDateToDDMMYYYY(value)
            );
        },
    },
    // {
    //     field: "is_sample_available",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <CheckCircleOutlineIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Sample Available" },
    //     filter: "agSetColumnFilter",
    //     minWidth: 200,
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //         // default: "Urgent",  
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    // {
    //     field: "can_get_sample",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <CheckCircleOutlineIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Can Get Sample" },
    //     filter: "agSetColumnFilter",
    //     minWidth: 200,
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //         // default: "Urgent",  
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    // {
    //     field: "need_to_see_process",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <CheckCircleOutlineIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Need to See Process" },
    //     filter: "agSetColumnFilter",
    //     minWidth: 200,
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //         // default: "Urgent",  
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    // {
    //     field: "process_cable_used_in",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <CheckCircleOutlineIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Process Cable Used In" },
    //     filter: "agSetColumnFilter",
    //     minWidth: 200,
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //         // default: "Urgent",  
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    // {
    //     field: "specific_pull_force_required",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <CheckCircleOutlineIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Specific Pull Force Required" },
    //     filter: "agSetColumnFilter",
    //     minWidth: 200,
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //         // default: "Urgent",  
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    // {
    //     field: "pull_force_value",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <StraightenIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Pull Force Value" },
    //     filter: "agSetColumnFilter",
    //     minWidth: 200,
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //         // default: "Urgent",  
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    // {
    //     field: "automated_handling_process",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <CheckCircleOutlineIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Automated Handling Process" },
    //     filter: "agSetColumnFilter",
    //     minWidth: 200,
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //         // default: "Urgent",  
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    // {
    //     field: "automated_handling_details",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <CheckCircleOutlineIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Automated Handling Details" },
    //     filter: "agSetColumnFilter",
    //     minWidth: 200,
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //         // default: "Urgent",  
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    // {
    //     field: "contact_points_with_elements",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <CheckCircleOutlineIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Contact Points with Elements" },
    //     cellRenderer: (params) => {
    //         const value = params?.value;
    //         // Ensure value exists and is a string
    //         if (typeof value === "string" && value) {
    //             const itemList = value.split(", ");
    //             return (
    //                 <>
    //                     {itemList?.map((item, index) => (
    //                         <OemText key={index} value={item.trim()} />
    //                     ))}
    //                 </>
    //             );
    //         }
    //         return null;
    //     },
    //     filter: "agSetColumnFilter",
    //     minWidth: 200,
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //         // default: "Urgent",  
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    // {
    //     field: "sun_lite_resistant_plastic",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <CheckCircleOutlineIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Sun Light Resistant Plastic" },
    //     minWidth: 200,
    //     filter: "agSetColumnFilter",
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //         // default: "Urgent",  
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    // {
    //     field: "ul_listing_required",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <CheckCircleOutlineIcon sx={{ height: "18px", width: "18px" }} />, displayName: "UL Listing Required" },
    //     cellRenderer: (params) => {
    //         const value = params?.value;
    //         // Ensure value exists and is a string
    //         if (typeof value === "string") {
    //             const itemList = value.split(", ");
    //             return (
    //                 <>
    //                     {itemList?.map((item, index) => (
    //                         <OemText key={index} value={item.trim()} />
    //                     ))}
    //                 </>
    //             );
    //         }
    //         return null;
    //     },
    //     minWidth: 200,
    //     filter: "agSetColumnFilter",
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //         // default: "Urgent",  
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
    // {
    //     field: "gamma_ray_sterilize",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <CheckCircleOutlineIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Gamma Ray Sterilize" },
    //     minWidth: 200,
    //     filterParams: {
    //         defaultToNothingSelected: true,  // Ensures no default is selected at start
    //         // default: "Urgent",  
    //     },
    //     hide: isMobile,
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: params.data.notification_status==false?"#222222":"#272727", 
    //             color: "white",
    //         };
    //     },
    // },
];
export default columnDefs;