import { Link, useNavigate } from "react-router-dom";
import "./index.css";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import lakeCable from "../../assets/images/lake-cable.png";
import { useState, useEffect, useRef } from "react";
import NotificationAddOutlinedIcon from "@mui/icons-material/NotificationAddOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
// import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { styled } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import ForumIcon from '@mui/icons-material/Forum';
import "./index.css";
import SideDrawer from "../sidebar/SideDrawer";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotificationList, markNotificationStatus, setFeedbackModal } from "../../actions/ticketActions";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const StyledDrawer = styled(Drawer)({
    "& .MuiPaper-root": {
        backgroundColor: "#1d2125",
        color: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        paddingTop: "16px",
    },
});
const Navbar1 = () => {
    const [activeNav, setActiveNav] = useState("projects");
    const [openSideBar, setOpenSideBar] = useState(false);
    const notificationRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openNotification, setOpenNotification] = useState(false);
    const { notificationList } = useSelector(state => state.tickets);
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setOpenSideBar(open);
    };
    const handleNotification = (event) => {
        event.stopPropagation();
        setOpenNotification(!openNotification);
    }
    useEffect(() => {
        dispatch(fetchNotificationList());
    }, []);
    const handleClickOutside = (event) => {
        // Check if the click is outside the notification container
        if (notificationRef.current && !notificationRef.current.contains(event.target) && (event?.target?.className!=="notification_count" && event?.target?.className!=="count_text")) {
            setOpenNotification(false); // Update state if clicked outside
        }
    };

    useEffect(() => {
        // Add event listener on mount
        document.addEventListener("mousedown", handleClickOutside);

        // Cleanup event listener on unmount
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickNotification = (ticketID, notificationID) => {
        navigate(`/edit-ticket/?ticketId=${ticketID}`);
        dispatch(markNotificationStatus(ticketID));
        setOpenNotification(false);
    }

    return (
        <>
            <nav className="main_nav">
                <div className="items">
                    <img src={lakeCable} alt="logo" height={70} width={150} />

                </div>
                <div className="items" style={{ color: 'white' }}>
                    <div style={{ display: "flex"}}>
                        <div className="icon_element" onClick={handleNotification} >
                            <NotificationAddOutlinedIcon />
                            {notificationList?.filter((notification) => notification.Seen === false).length > 0 && <div className="notification_count">
                                <span className="count_text">{notificationList?.filter((notification) => notification.Seen === false).length}</span>
                            </div>}
                        </div>
                        <div className="icon_element" onClick={() => { dispatch(setFeedbackModal(true)) }}>
                            <ForumIcon />
                        </div>
                        {/* <div className="icon_element">
                            <InfoOutlinedIcon />
                        </div> */}
                        {/* <SettingsOutlinedIcon className="icon_element" /> */}
                        {/* <div className="icon_element">
                            <AccountCircleRoundedIcon />
                        </div> */}
                        <MenuIcon className="icon_element" id="menu_bar" onClick={toggleDrawer("left", true)} />
                        <StyledDrawer
                            anchor="left"
                            open={openSideBar}
                            onClose={toggleDrawer("left", false)}
                        >
                            <SideDrawer />
                        </StyledDrawer>
                    </div>
                </div>
            </nav>
            {
                openNotification &&
                <div className="notification_container" ref={notificationRef}>{
                    notificationList?.map((item) => {
                        return (
                            <div className="notification_item" key={item?.NotificationID} onClick={() => handleClickNotification(item?.TicketID, item?.NotificationID)}>
                                {/* <AccessTimeIcon/> */}
                                <span className="notification_text">#{item?.TicketID} {item?.Message} <br></br><span className="notification_type">{item?.NotificationType}</span></span>
                                {!item?.Seen && <div className="unread_mark"></div>}
                            </div>
                        )
                    })
                }</div>
            }
        </>
    );
}
export default Navbar1;