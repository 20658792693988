import { AgGridReact } from "@ag-grid-community/react";
import { useEffect, useMemo, useRef, useState } from "react";
import categoryColumns from "../tableStructures/categoryColumns";
import { useDispatch, useSelector } from "react-redux";
import { fetchEmployeeTableData, fetchGroupTableData, setCategoryLoading, setLoadUpdateCategory, updateEmployeeGroupData, updateGroupData } from "../../actions/ticketActions";
import AddIcon from '@mui/icons-material/Add';
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import DataSaverOnOutlinedIcon from '@mui/icons-material/DataSaverOnOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from "@mui/material";
import groupColumns from "../tableStructures/groupColumns";
import AddGroupModal from "./AddGroupModal";
import employeeGroupColumns from "../tableStructures/employeeGroupColumns";
import AddEmployeeModal from "./AddEmlpoyeeModal";
const isMobile = window.innerWidth <= 768;


const ManageGroups = () => {
    const [gridApi, setGridApi] = useState(null);
    const [gridHeight, setGridHeight] = useState("");
    const [rowData, setRowData] = useState([]);
    const [employeeRowData, setEmployeeRowData] = useState([]);
    const [quickFilterText, setQuickFilterText] = useState("");
    const [dirtyFields, setDirtyFields] = useState([]);
    const [dirtyFieldsGroupEmployee, setDirtyFieldsGroupEmployee] = useState([]);
    const [isCategoryModal, setIsCategoryModal] = useState(false);
    const [addEmployeeModal, setAddEmployeeModal] = useState(false);
    const [activeTab, setActiveTab] = useState("group");
    const rowHeight = isMobile ? 42 : 80;
    const { groupTableData, assignedToPersonMap, groupEmployeData, assignedToGroupMap, employeeTableData, categoryLoading } = useSelector(state => state.tickets);
    const gridRef = useRef();
    const dispatch = useDispatch();
    const gridStyle = useMemo(
        () => ({
            height: rowData?.length > 0 ? gridHeight : "300px",
            maxHeight: isMobile ? gridHeight : "700px",
            width: "100%",
            // borderLeft: `2px solid ${colorcode}`,
            backgroundColor: isMobile ? "none" : "#171717",
            overflowX: "auto",
        }),
        [gridHeight, rowData, groupTableData, quickFilterText]
    );
    const containerStyle = useMemo(
        () => ({
            width: "100%",
            height: "auto",
            minHeight: "200px",
        }),
        []
    );
    useEffect(() => {
        if (groupTableData?.data?.length > 0) {
            setRowData(groupTableData?.data);
        }
    }, [groupTableData]);
    useEffect(() => {
        if (employeeTableData?.data?.length > 0) {
            setEmployeeRowData(employeeTableData?.data?.map((item) => {
                return {
                    ...item,
                    "EmployeeID": assignedToPersonMap[item?.EmployeeID] + `(${item?.EmployeeID})`,
                    "GroupID": assignedToGroupMap[item?.GroupID] + `(${item?.GroupID})`,
                    "emp_list": groupEmployeData.filter(item => item.type === "employee"),
                    "groupTableData": groupTableData?.data
                }
            })
            );
        }
    }, [employeeTableData]);

    const onGridReady = (params) => {
        setGridApi(params.api);
        gridRef.current = params.api;
        setTimeout(() => {
            params.api.onFilterChanged();
        }, 500);
        params.api.onFilterChanged();
    };

    useEffect(() => {
        dispatch(setCategoryLoading(true));
        dispatch(fetchGroupTableData());
        dispatch(fetchEmployeeTableData());
    }, [])

    const onCellValueChanged = (params) => {
        const { colDef, oldValue, newValue, data } = params;
        if (oldValue !== newValue) {
            setDirtyFields([...dirtyFields, data]);
        }
    };
    const onGroupEmployeeCellValueChanged = (params) => {
        const { colDef, oldValue, newValue, data } = params;
        if (oldValue !== newValue) {
            setDirtyFieldsGroupEmployee(
                (prevDirtyFields) => {
                    const index = prevDirtyFields.findIndex((field) => field.EmployeeGroupID === data.EmployeeGroupID);
                    if (index !== -1) {
                        // Update the existing object at the found index
                        const updatedFields = [...prevDirtyFields];
                        updatedFields[index] = colDef.field === "EmployeeID" ? {
                            ...updatedFields[index],
                            [colDef.field]: colDef.field === "EmployeeID" ? Number(newValue.match(/\((.*?)\)/)[1]) : newValue,
                        } : {
                            ...updatedFields[index],
                            [colDef.field]: colDef.field === "GroupID" ? Number(newValue.match(/\((.*?)\)/)[1]) : newValue,
                        };
                        return updatedFields;
                    } else {
                        // Add a new entry if no match is found
                        return [
                            ...prevDirtyFields,
                            colDef.field === "EmployeeID" ? {
                                EmployeeGroupID: data.EmployeeGroupID,
                                [colDef.field]: colDef.field === "EmployeeID" ? Number(newValue.match(/\((.*?)\)/)[1]) : newValue,
                            } : {
                                EmployeeGroupID: data.EmployeeGroupID,
                                [colDef.field]: colDef.field === "GroupID" ? Number(newValue.match(/\((.*?)\)/)[1]) : newValue,
                            },
                        ];
                    }
                }
            );
        }
    };

    useEffect(() => {
        if (gridApi) {
            gridApi.setGridOption("quickFilterText", quickFilterText);
            gridApi.onFilterChanged();
            const displayedRows = [];
            gridApi?.forEachNodeAfterFilterAndSort((node) => {
                displayedRows.push(node.data);
            });
            const rowCount = displayedRows.length;
            const calculatedHeight = (rowCount) * (rowHeight);
            setGridHeight(`${calculatedHeight}px`);
        }
    }, [gridApi, categoryColumns, isMobile, rowData, quickFilterText, groupTableData]);
    const handleAddCategory = () => {
        setIsCategoryModal(true);
    }
    const handleAddEmployee = () => {
        setAddEmployeeModal(true);
    }
    const handleAddEmployeeClose = () => {
        setAddEmployeeModal(false);
    }
    const handleCategoryClose = () => {
        setIsCategoryModal(false)
    }
    const handleUpdateCategory = () => {
        dispatch(setLoadUpdateCategory(true));
        dispatch(updateGroupData(dirtyFields));
    }
    const handleUpdateGroupEmployee = () => {
        dispatch(setLoadUpdateCategory(true));
        dispatch(updateEmployeeGroupData(dirtyFieldsGroupEmployee));
    }
    return (
        <>
            <div className="table_main">
                <div className="tab_container">
                    <div className={activeTab === "group" ? "tab_active_div" : "tab_div"} onClick={() => setActiveTab("group")}>Group Table</div>
                    <div className={activeTab === "employee" ? "tab_active_div" : "tab_div"} onClick={() => setActiveTab("employee")}>Employee Table</div>
                </div>
                <div className="action_container">
                    <div className="search_container_category">
                        <input type="text" placeholder="Search..." onChange={(e) => setQuickFilterText(e.target.value)} className="searchbar_category"/>
                        <SearchOutlinedIcon className="search_icon" />
                    </div>
                    <div className="btn_div">
                        {activeTab === "group" && <button className="create_btn"><div className="btn_content" onClick={handleUpdateCategory}><DataSaverOnOutlinedIcon className="add_icon" loading /><span className="btn_text">Save Changes</span></div></button>}
                        {activeTab === "group" && <button className="create_btn"><div className="btn_content" onClick={handleAddCategory}><AddIcon className="add_icon" /> <span className="btn_text">Add Group</span></div></button>}
                        {activeTab === "employee" && <button className="create_btn"><div className="btn_content" onClick={handleUpdateGroupEmployee}><DataSaverOnOutlinedIcon className="add_icon" loading /><span className="btn_text">Save Changes</span></div></button>}
                        {activeTab === "employee" && <button className="create_btn"><div className="btn_content" onClick={handleAddEmployee}><AddIcon className="add_icon" /> <span className="btn_text">Add Employee</span></div></button>}
                    </div>
                </div>
                <div className={`ag-theme-quartz-dark`} style={containerStyle}>
                    <div style={gridStyle}>
                        <AgGridReact
                            ref={gridRef}
                            rowData={activeTab === "group" ? rowData : employeeRowData}
                            columnDefs={activeTab === "group" ? groupColumns : employeeGroupColumns}
                            defaultColDef={{
                                resizable: true,
                                sortable: true,
                                autoSize: true,
                                filter: true,
                                flex: 1,
                                minWidth: 100,
                                // cellStyle: { backgroundColor: "#272727", color: "white" },
                                headerClass: "custom-header",
                                width: "100%",
                            }}
                            // headerHeight={window.innerWidth <= 768 ? 0 : 40}
                            domLayout={Number(gridHeight.slice(0, -2)) < 800 ? "autoHeight" : "normal"}
                            rowSelection="multiple"
                            onGridReady={onGridReady}
                            suppressServerSideFullWidthLoadingRow={true}
                            // rowHeight={isMobile ? "80px" : "42px"}
                            onCellValueChanged={activeTab === "group" ? onCellValueChanged : onGroupEmployeeCellValueChanged}
                            loadingOverlayComponent={() => (
                                categoryLoading ? <Box sx={{ display: "flex", height: "800px", justifyContent: "center", alignItems: "center" }}>
                                    <CircularProgress />
                                </Box> :
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                        backgroundColor: "#272727",
                                        color: "white",
                                        width: "100%"
                                    }}>
                                        No data found
                                    </div>
                            )}
                        />
                    </div>
                </div>
            </div>
            <AddGroupModal isCategoryModal={isCategoryModal} handleCategoryClose={handleCategoryClose} />
            <AddEmployeeModal isCategoryModal={addEmployeeModal} handleCategoryClose={handleAddEmployeeClose} />
        </>
    );
}
export default ManageGroups;