import React, { useState, useEffect } from "react";

const CustomDropdownEditor = (props) => {
    const [searchText, setSearchText] = useState("");
    const [filteredOptions, setFilteredOptions] = useState([]);

    useEffect(() => {
        // Initialize options based on the passed values
        setFilteredOptions(props.values || []);
    }, [props.values]);

    const handleSearchChange = (event) => {
        const text = event.target.value;
        setSearchText(text);

        // Filter options based on the search input
        const filtered = props.values.filter((option) =>
            option.toLowerCase().includes(text.toLowerCase())
        );
        setFilteredOptions(filtered);
    };

    const handleOptionSelect = (value) => {
        props.stopEditing(); // Notify the grid that editing is complete
        props.onValueChange(value); // Pass the selected value back to the grid
    };

    return (
        <div style={{ padding: "5px", background: "#222222", color: "#fff" }}>
            {/* Search Input */}
            <input
                type="text"
                value={searchText}
                onChange={handleSearchChange}
                placeholder="Search..."
                style={{
                    width: "100%",
                    padding: "5px",
                    marginBottom: "5px",
                    borderRadius: "4px",
                    border: "1px solid #444",
                    backgroundColor: "#333",
                    color: "#fff",
                }}
            />
            {/* Filtered Options */}
            <ul style={{ listStyle: "none", margin: 0, padding: 0, maxHeight: "200px", overflowY: "auto" }}>
                {filteredOptions.map((option, index) => (
                    <li
                        key={index}
                        onClick={() => handleOptionSelect(option)}
                        style={{
                            padding: "5px",
                            cursor: "pointer",
                            backgroundColor: "#444",
                            marginBottom: "2px",
                        }}
                    >
                        {option}
                    </li>
                ))}
                {filteredOptions.length === 0 && (
                    <li style={{ padding: "5px", color: "#888" }}>No results found</li>
                )}
            </ul>
        </div>
    );
};

export default CustomDropdownEditor;
