
import { useEffect, useState } from "react";
import RevisionTicketModal from "../EditTicket/RevisionTicketModal";
import BasicModal from "../EditTicket/TicketModal";
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CreateTicketModal from "../TicketOperations/CreateTicketModal";
import ForumIcon from '@mui/icons-material/Forum';
import "./index.css";
import MainBoard from "./MainBoard";
import { setFeedbackModal, userProtected } from "../../actions/ticketActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FeedbackModal from "../sidebar/FeedbackModal";
import CategoryBoard from "./CategoryBoard";
import ModelTrainingOutlinedIcon from '@mui/icons-material/ModelTrainingOutlined';
import ManageSkills from "./ManageSkills";
import ManageGroups from "./ManageGroups";
import Groups2Icon from '@mui/icons-material/Groups2';

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState("timeline");
  const {
    editTicket,
    currentUser
  } = useSelector(state => state.tickets);
  useEffect(() => {
    dispatch(userProtected());
  }, []);

  useEffect(() => {
    if (editTicket?.status == "success") {
      navigate("/");
    }
  }, [editTicket]);
  useEffect(() => {
    if (window.location.pathname === "/manage-category") {
      setSelectedItem("category")
    } else if (window.location.pathname === "/") {
      setSelectedItem("timeline")
    } else if (window.location.pathname === "/employee-skill") {
      setSelectedItem("employee-skill")
    } else if (window.location.pathname === "/manage-groups") {
      setSelectedItem("manage-groups")
    }
  }, [window.location.pathname])
  return (
    <>
      <div className="main_container">
        <div className="container_item">
          <div className="sidebar_container">
            <div className="sidebar_items">
              <p className="board_text">Your Board</p>
              <div className={selectedItem === "timeline" ? "board_active" : "board_items"} onClick={() => { setSelectedItem("timeline"); navigate("/"); }}>
                <ViewTimelineOutlinedIcon className="sidebar_icon" />
                <span>Timeline</span>
              </div>
              {currentUser?.is_admin && <div className={selectedItem === "category" ? "board_active" : "board_items"} onClick={() => { setSelectedItem("category"); navigate("/manage-category"); }}>
                <CategoryOutlinedIcon className="sidebar_icon" />
                <span>Category</span>
              </div>}
              {currentUser?.is_admin && <div className={selectedItem === "employee-skill" ? "board_active" : "board_items"} onClick={() => { setSelectedItem("employee-skill"); navigate("/employee-skill"); }}>
                <ModelTrainingOutlinedIcon className="sidebar_icon" />
                <span>Employee Skill</span>
              </div>}
              {currentUser?.is_admin && <div className={selectedItem === "manage-groups" ? "board_active" : "board_items"} onClick={() => { setSelectedItem("manage-groups"); navigate("/manage-groups"); }}>
                <Groups2Icon className="sidebar_icon" />
                <span>Manage Groups</span>
              </div>    }          
              {/*<div className={selectedItem === "activesprints" ? "board_active" : "board_items"} onClick={() => setSelectedItem("activesprints")}>
                        <RecyclingOutlinedIcon className="sidebar_icon" />
                        <span>Active Sprints</span>
                    </div>
                    <div className={selectedItem === "calendar" ? "board_active" : "board_items"} onClick={() => setSelectedItem("calendar")}>
                        <CalendarMonthOutlinedIcon className="sidebar_icon" />
                        <span>Calendar</span>
                    </div>
                    <div className={selectedItem === "reports" ? "board_active" : "board_items"} onClick={() => setSelectedItem("reports")}>
                        <ReceiptLongOutlinedIcon className="sidebar_icon" />
                        <span>Reports</span>
                    </div> */}
              {/* <div className={selectedItem === "feedback" ? "board_active" : "board_items"} onClick={() => { setSelectedItem("feedback"); dispatch(setFeedbackModal(true)) }}>
                <ForumIcon className="sidebar_icon" />
                <span>Feedback</span>
              </div> */}
              {/* <div className={selectedItem === "settings" ? "board_active" : "board_items"} onClick={() => { setSelectedItem("settings"); }}>
                <SettingsOutlinedIcon className="sidebar_icon" />
                <span>Settings</span>
              </div> */}
            </div>
          </div>
        </div>
        <div className="container_item">
          {selectedItem === "timeline" && <MainBoard />}
          {selectedItem === "category" && <CategoryBoard />}
          {selectedItem === "employee-skill" && <ManageSkills />}
          {selectedItem === "manage-groups" && <ManageGroups />}        
        </div>
      </div>
      <RevisionTicketModal />
      <CreateTicketModal />
      <BasicModal />
      <FeedbackModal />
    </>
  );
};

export default Dashboard;