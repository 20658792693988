import React, { useEffect, useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import FilterAltIcon from "@mui/icons-material/FilterAlt"; // Active filter icon

const CustomHeader = (props) => {
    const [isFilterActive, setIsFilterActive] = useState(false);

    useEffect(() => {
        setIsFilterActive(isFilterActive);
    }, [isFilterActive]);
    const onFilterClick = (event) => {
        event.stopPropagation();
        props.api.showColumnFilter(props.column, event.target);
        props.api.getFilterInstance(props.column.getColId(), (filterInstance) => {
            const model = filterInstance.getModel();
            setIsFilterActive(!model); 
        });
    };
    const onIconClick = (event) => {
        event.stopPropagation();
        props.api.showColumnMenuAfterButtonClick(props.column, event.target);
    };

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "0 5px",
            }}
            onClick={onFilterClick}
        >
            {props.icon && (
                <span style={{
                    marginRight: "5px",
                    display: "flex",
                    alignItems: "center", // Ensures icon is aligned with text
                }}
                >
                    {props.icon}
                </span>
            )}
            <span style={{
                display: "flex",
                alignItems: "center" // Ensures text is aligned with icon
            }}>
                {props.displayName}
            </span>
            {isFilterActive ?
                <FilterAltIcon sx={{ color: "#ef6e35", marginLeft: "20px" }} onClick={onFilterClick} /> :
                <FilterListIcon sx={{ color: "gray", marginLeft: "20px" }} onClick={onFilterClick} />
            }
            <SwapVertIcon sx={{ color: "gray", marginLeft: "10px" }} onClick={onIconClick} /> {/* Optional filter icon */}
        </div>
    );
};

export default CustomHeader;
