import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select, { components } from "react-select";
import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";
import CreatableSelect from "react-select/creatable";
import RichText from "./RichText";
import apiUrl from "../common/apiUrl";
import { automatedHandlings, cableContactPointList, gammaRays, getAvailableSample, isSampleAvailable, processCableList, pullForceNeeded, seeProcess, sunLightResistancePlastic, UlListing } from "./DropdownOptions";
import { useDispatch, useSelector } from "react-redux";
import { downloadAttachment, fetchGroupEmployeData, fetchInvolveTickets, fetchNotificationList, fetchTicketStatus, putSignedUrl, setAttachmentLoading, setCategories, setContactPoints, setCreateData, setDownloadFileTemp, setGroupEmployeData, setIsBackdropLoading, setMistakeCodes, setOpenCreateModal, setPriorities, setPutSignedSuccess, setReasonForNoQuote, setTicketStatus, setUlListings, setUploadUrlTemp, setWorkTypes, uploadUrlTempEpic } from "../../actions/ticketActions";
import DropdownControllers from "../dashboard/DropdownControllers";
import CustomMultiValue from "./CustomMultiValue";
import pdfIcon from "../../assets/images/pdfIcon.png";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { showToastError } from "../common/toast";
import { CircularProgress } from "@mui/material";
import constantKeys from "./constantKeys";
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';

const isMobile = window.innerWidth <= 768;

const controlStyles = {
  control: (provided) => ({
    ...provided,
    boxShadow: "none",
    border: "none",
    backgroundColor: "rgb(39, 39, 39)",
    color: "#fff",
    width: isMobile ? "95%" : "50%",
    marginTop: "10px",
  }),
  option: (provided) => ({
    ...provided,
    backgroundColor: "rgb(39, 39, 39)",
    color: "#fff",
    border: "none",
    boxShadow: "none",
    cursor: "pointer",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#fff",
  }),
  input: (provided) => ({
    ...provided,
    color: "#fff",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#ccc",
  }),
};

const CreateTicketForm = () => {
  const dispatch = useDispatch();
  const {
    register,
    watch,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    field,
    formState: { errors, dirtyFields },
  } = useForm();
  const categoryValue = watch("category");
  const isSampleValue = watch("isSample");
  const pullForceNeed = watch("pullForceNeed");
  const testingRequired = watch("testingRequired");
  const automatedHandling = watch("automatedHandling");
  const quotePartRequired = watch("quotePartRequired");
  const orderPartRequired = watch("orderPartRequired");
  const wordSpecsRequired = watch("wordSpecsRequired");
  const cadDrawingsRequired = watch("cadDrawingsRequired");
  const [fileData, setFileData] = useState();
  const {
    mistakeCodes,
    reasonForNoQuote,
    contactPoints,
    ulListings,
    ticketStatus,
    categories,
    priorities,
    workTypes,
    groupEmployeData,
    ticketData,
    customerNameList,
    uploadUrlTemp,
    downloadFileTemp,
    attachmentLoading,
    putSignedSuccess,
  } = useSelector(state => state.tickets);

  const currentValues = getValues();
  const modifiedData = Object.keys(dirtyFields).reduce((acc, key) => {
    acc[key] = currentValues[key];
    return acc;
  }, {});
  const [attachmentsList, setAttachmentsList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [keywordsValue, setKeywordsValue] = useState([]);
  const [footageValue, setFootageValue] = useState();
  const [validationMsg, setValidationMsg] = useState();
  useEffect(() => {
    if (Object.keys(modifiedData).length !== 0) {
      const temp = {
        ...JSON.parse(localStorage.getItem("draftData")),
        ...modifiedData,
      }
      localStorage.setItem("draftData", JSON.stringify(temp));
      localStorage.setItem("draftAvailable", "false");
    }
  }, [modifiedData]);

  useEffect(() => {
    return (
      localStorage.setItem("draftAvailable", "true")
    )
  }, []);
  useEffect(() => {
    if (quotePartRequired || orderPartRequired || wordSpecsRequired || cadDrawingsRequired) {
      setValidationMsg("");
    } else {
      setValidationMsg("At least one field is required");
    }
  }, [quotePartRequired, orderPartRequired, wordSpecsRequired, cadDrawingsRequired]);

  const modifyListToString = (arr) => arr?.map(item => item.value).join(", ");
  const onSubmit = (data) => {
    let ulLlistingModifiedList, cableContactPointsModifiedList = modifyListToString(data?.cableContactPoints, ""), processCableModifiedList = modifyListToString(data?.processAapplication, "");
    if (data?.ulListingRequired) {
      ulLlistingModifiedList = data?.ulListingRequired?.map((item) => { return item?.id; });
    }

    const groupEmployeId = data?.emailsToNotify?.map((item) => {
      return item?.type === "group" ? { "GroupID": item?.id } : { "EmployeeID": item?.id };
    });
    if (quotePartRequired || orderPartRequired || wordSpecsRequired || cadDrawingsRequired) {
      dispatch(setIsBackdropLoading(true));
      const ticketData = {
        Title: data?.title,
        WorkTypeID: Number(data?.workType?.id),
        PriorityID: Number(data?.priority?.id),
        CategoryID: Number(data?.category?.id),

        is_sample_available: data?.isSample?.value === "Yes" ? true : false,
        can_get_sample: data?.getSample?.value === "Yes" ? true : false,
        need_to_see_process: data?.isProcess?.value === "Yes" ? true : false,
        process_cable_used_in: processCableModifiedList,
        specific_pull_force_required: data?.pullForceNeed?.value === "Yes" ? true : false,
        pull_force_value: Number(data?.newColumnPullForce),
        automated_handling_process: data?.automatedHandling?.value === "Yes" ? true : false,
        automated_handling_details: data?.newColumnAutomated,
        sun_lite_resistant_plastic: data?.sunLightRes?.value === "Yes" ? true : false,
        contact_points_with_elements: cableContactPointsModifiedList,
        gamma_ray_sterilize: data?.gammaRaysSterilize?.value === "Yes" ? true : false,
        ulListingRequired: ulLlistingModifiedList,
        SpecialTesting: data?.testingRequired?.value === "Yes" ? true : false,
        SpecialTestingText: data?.testingProcedure,
        TicketStatusID: constantKeys.ticketStatusDefaultId,
        AssignedToGroupID: data?.assigned_to?.type == "employee" ? null : data?.assigned_to?.id,
        AssignedToPersonID: data?.assigned_to?.type == "employee" ? data?.assigned_to?.id : null,
        Description: data?.description,
        CustomerName: data?.customerName?.id,
        AssignedPartNumber: modifyListToString(keywordsValue),
        QuotePartRequired: Number(data?.quotePartRequired),
        OrderPartRequired: Number(data?.orderPartRequired),
        WordSpecsRequired: Number(data?.wordSpecsRequired),
        CADDrawingsRequired: Number(data?.cadDrawingsRequired),
        footage: Number(data?.footage?.split(",").join("")),
        emailsToNotify: groupEmployeId,
        attachments: attachmentsList,
        Price: data?.price,
      };
      fetch(apiUrl.createTicket, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        },
        body: JSON.stringify(ticketData)
      })
        .then(response => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          localStorage.removeItem("draftData");
          localStorage.removeItem("draftAvailable");
          dispatch(setIsBackdropLoading(false));
          return response.json();
        })
        .then(data => {
          dispatch(fetchInvolveTickets());
          dispatch(setOpenCreateModal(false));
          dispatch(setCreateData(data));
          dispatch(fetchNotificationList());
          dispatch(setIsBackdropLoading(false));
        })
        .catch((error) => {
          console.error("Error:", error);
          dispatch(setIsBackdropLoading(false));
        });
    } else {
      return;
    }
    localStorage.removeItem("draftAvailable");
    localStorage.removeItem("draftData");
    // reset();
  };

  useEffect(() => {
    localStorage.setItem("mistakeCode", JSON.stringify(mistakeCodes));
    localStorage.setItem("reasonForNoQuote", JSON.stringify(reasonForNoQuote));
    localStorage.setItem("contactPoints", JSON.stringify(contactPoints));
    localStorage.setItem("ulListings", JSON.stringify(ulListings));
    localStorage.setItem("ticketStatus", JSON.stringify(ticketStatus));
    localStorage.setItem("categories", JSON.stringify(categories));
    localStorage.setItem("priorities", JSON.stringify(priorities));
    localStorage.setItem("workTypes", JSON.stringify(workTypes));
    localStorage.setItem("groupEmployeData", JSON.stringify(groupEmployeData));
  }, [mistakeCodes, reasonForNoQuote, contactPoints, ulListings, ticketStatus, categories, priorities, workTypes, groupEmployeData]);

  useEffect(() => {
    if (ticketData) {
      reset({
        title: ticketData.title || "",
        workType: ticketData?.workType,
        priority: ticketData?.priority,
        category: ticketData?.category,
        ticketStatus: ticketData?.ticketStatus,
        description: ticketData?.description,
        customerName: ticketData?.customerName,
        assignedPartNumber: ticketData?.assignedPartNumber,
        quotePartRequired: ticketData?.quotePartRequired,
        wordSpecsRequired: ticketData?.wordSpecsRequired,
        orderPartRequired: ticketData?.orderPartRequired,
        cadDrawingsRequired: ticketData?.cadDrawingsRequired,
        footage: ticketData?.footage,
        isSample: ticketData?.isSample,
        getSample: ticketData?.getSample,
        isProcess: ticketData?.isProcess,
        processApplication: ticketData?.processAapplication,
        pullForceNeed: ticketData?.pullForceNeed,
        newColumnAutomated: ticketData?.newColumnAutomated,
        newColumnPullForce: ticketData?.newColumnPullForce,
        sunLightRes: ticketData?.sunLightRes,
        gammaRaysSterilize: ticketData?.gammaRaysSterilize,
        ulListingRequired: ticketData?.ulListingRequired,
        emailsToNotify: ticketData?.emailsToNotify,
        attachments: ticketData?.attachments
      });
    }
  }, [ticketData]);

  const [urls, setUrls] = useState([]);
  const handleUploadImage = (e) => {
    const file = e?.currentTarget?.files?.[0];
    const imageType = file.name.substring(file.name.lastIndexOf('.') + 1);;
    const payload = {
      "file_extension": imageType,
    }
    dispatch(uploadUrlTempEpic(payload));
    const url = URL.createObjectURL(file);
    setUrls([...urls, url]);
    setFileData(file);
  };

  useEffect(() => {
    const file = fileData;

    if (file && uploadUrlTemp?.url) {
      dispatch(setAttachmentLoading(true));
      dispatch(putSignedUrl({
        url: uploadUrlTemp?.url,
        data: file
      }, dispatch))
    }

  }, [uploadUrlTemp]);
  useEffect(() => {
    if (putSignedSuccess?.code == "00000") {
      if (uploadUrlTemp?.file_name) {
        const temp = [...attachmentsList];
        temp.push(uploadUrlTemp?.file_name);
        setAttachmentsList(temp);
      }
      dispatch(setPutSignedSuccess({}));
    }
  }, [putSignedSuccess])

  useEffect(() => {
    if (ticketData?.processAapplication) {
      setValue("processAapplication", ticketData?.processAapplication);
      setValue("cableContactPoints", ticketData?.cableContactPoints);
      setValue("ulListingRequired", ticketData?.ulListingRequired);
    }
  }, [ticketData, setValue, field]);

  useEffect(() => {
    if (downloadFileTemp?.download_url) {
      const pdfUrl = downloadFileTemp?.download_url;
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.target = "_blank";
      link.download = downloadFileTemp?.download_url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [downloadFileTemp]);

  const handleAttachmentClick = (index) => {
    dispatch(downloadAttachment(attachmentsList[index]));
  }
  const deleteAttachment = (item, index) => {
    setUrls(urls?.splice(index));
    const temp = attachmentsList.filter(val => val != item);
    setAttachmentsList(temp);
  }
  const createOption = (label) => ({
    label,
    value: label,
  });
  const handleKeyDown = (event) => {
    if (!inputValue) {
      return;
    }
    switch (event.key) {
      case "Enter":
      case "Tab":
        if (keywordsValue?.length < 10 && inputValue?.length <= 50) {
          setKeywordsValue((prev) => [...prev, createOption(inputValue)]);
          setInputValue("");
          event.preventDefault();
        } else if (inputValue?.length > 50) {
          showToastError("Please Enter maximum 50 characters");
        } else {
          showToastError("You can Enter maximum 10 keywords");
        }
        break
      default:
        break
    }
  };


  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        {/* Render an icon and the option label */}
        <div style={{ display: "flex", alignItems: "center" }}>
          {props.data.icon && <span style={{ marginRight: "8px" }}>{props.data.icon}</span>}
          {props.data.label}
        </div>
      </components.Option>
    );
  };
  useEffect(() => {
    dispatch(fetchTicketStatus());
    dispatch(fetchGroupEmployeData());
  }, []);
  useEffect(() => {
    return () => {
      setAttachmentsList([]);
      dispatch(setDownloadFileTemp({}));
      dispatch(setUploadUrlTemp({}));
    }
  }, []);
  const handleFootageChange = (e) => {
    const rawValue = e.target.value.replace(/,/g, "");
    if (!isNaN(rawValue)) {
      const formattedValue = Number(rawValue).toLocaleString("en-US");
      setFootageValue(formattedValue);
      setValue("footage", rawValue); // Set the raw value in the form
    }
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form_main" id="create_form_main">
      <div className={errors.title ? "form_error_div" : "form_div"}>
        <label>Title <span>*</span></label><br></br>
        <input
          type="text"
          {...register("title", { required: true })}
          placeholder="Title"
          className="input_field_create"
        />
        <br></br>
        {errors.title && <span className="required_text">Title is required</span>}
      </div>
      <div className={errors.workType ? "form_error_div" : "form_div"}>
        <label>Work Type <span>*</span></label>
        <Controller
          name="workType"
          control={control}
          rules={{ required: "Work Type is required" }}
          render={({ field }) => (
            <Select {...field} options={workTypes} styles={controlStyles} placeholder="Select Work Type" />
          )}
        />
        <br></br>
        {errors.workType && <span className="required_text">Work Type is required</span>}
      </div>

      <hr className="field_seperator" />

      <div className={errors.priority ? "form_error_div" : "form_div"}>
        <label>Priority <span>*</span></label>
        <Controller
          name="priority"
          rules={{ required: "Priority is required" }}
          control={control}
          render={({ field }) => (
            <Select {...field} options={priorities} styles={controlStyles} placeholder="Select Priority" />
          )}
          defaultValue={{
            "id": 1,
            "value": "Normal",
            "label": "Normal"
          }}
        />
        <br></br>
        {errors.priority && <span className="required_text">Priority is required</span>}
      </div>

      <div className={errors.category ? "form_error_div" : "form_div"}>
        <label>Category <span>*</span></label>
        <Controller
          name="category"
          rules={{ required: "Category is required" }}
          control={control}
          render={({ field }) => (
            <Select {...field} options={categories} styles={controlStyles} placeholder="Select Category" />
          )}
        />
        <br></br>
        {errors.category && <span className="required_text">Category is required</span>}
      </div>

      {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && <div className={errors.isSample ? "form_error_div" : "form_div"}>
        <label>Is Sample Available? <span>*</span></label>
        <Controller
          name="isSample"
          control={control}
          rules={{ required: "Is Sample Available is required" }}
          render={({ field }) => (
            <Select {...field} options={isSampleAvailable} styles={controlStyles} placeholder="Select Category" />
          )}
        />
        <br></br>
        {errors.isSample && <span className="required_text">Is Sample Available is required</span>}
      </div>}

      {isSampleValue?.value === "Yes" && <div className={errors.getSample ? "form_error_div" : "form_div"}>
        <label>Can we get a sample? <span>*</span></label>
        <Controller
          name="getSample"
          control={control}
          rules={{ required: "Get Sample is required" }}
          render={({ field }) => (
            <Select {...field} options={getAvailableSample} styles={controlStyles} placeholder="Select Category" />
          )}
        />
        <br></br>
        {errors.getSample && <span className="required_text">Get Sample is required</span>}
      </div>}

      {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && <div className={errors.isProcess ? "form_error_div" : "form_div"}>
        <label>Do we need to see their process? <span>*</span></label>
        <Controller
          name="isProcess"
          rules={{ required: "This is required" }}
          control={control}
          render={({ field }) => (
            <Select {...field} options={seeProcess} styles={controlStyles} placeholder="Select Category" />
          )}
        />
        <br></br>
        {errors.isProcess && <span className="required_text">This is required</span>}
      </div>}
      {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && <div className={errors.processAapplication ? "form_error_div" : "form_div"}>
        <label>Process cable is used in <span>*</span></label><br></br>
        <Controller
          name="processAapplication"
          control={control}
          rules={{ required: "This is required" }}
          render={({ field }) => (
            <Select
              {...field}
              className="multi_select_dropdown"
              options={processCableList}
              isMulti={true}
              defaultValue={ticketData?.processAapplication}
              // value={selectedOptions}
              styles={DropdownControllers.multiControl}
              onChange={(selectedOptions) => {
                // Update the field value to ensure it syncs with React Hook Form
                field.onChange(selectedOptions);
              }}
              // defaultValue={defaultValue}
              components={{ Option: CustomOption, MultiValue: CustomMultiValue }}
            // formatCreateLabel={(inputValue) => `Create "${inputValue}"`}  
            />
          )}
        />
        <br></br>
        {errors.processAapplication && <span className="required_text">This is required</span>}
      </div>}

      {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && <div className={errors.pullForceNeed ? "form_error_div" : "form_div"}>
        <label>Cable needs specific pull force to remove jacket? <span>*</span></label>
        <Controller
          name="pullForceNeed"
          defaultValue={{
            "label": "No",
            "value": "No"
          }}
          rules={{ required: "This is required" }}
          control={control}
          render={({ field }) => (
            <Select {...field} options={pullForceNeeded} styles={controlStyles} placeholder="Select Category" />
          )}
        />
        <br></br>
        {errors.pullForceNeed && <span className="required_text">This is required</span>}
      </div>}

      {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && pullForceNeed?.value == "Yes" && <div className={errors.newColumnPullForce ? "form_error_div" : "form_div"}>
        <label>How much Force? <span>*</span></label><br></br>
        <input
          type="number"
          {...register("newColumnPullForce", { required: true })}
          className="input_field_create"
        />
        <span className="number_input_unit">PSI</span>
        <br></br>
        {errors.newColumnPullForce && <span className="required_text">This is required</span>}
      </div>}

      <div className={"form_div"}>
        <label>Special Testing Required?</label>
        <Controller
          name="testingRequired"
          defaultValue={{
            "label": "No",
            "value": "No"
          }}
          control={control}
          render={({ field }) => (
            <Select {...field} options={pullForceNeeded} styles={controlStyles} placeholder="Select Category" />
          )}
        />
        <br></br>
        {/* {errors.testingRequired && <span className="required_text">This is required</span>} */}
      </div>

      {testingRequired?.value === "Yes" && <div className={"form_div"}>
        <label>Please mention the Testing Procedure? </label><br></br>
        <input
          type="text"
          {...register("testingProcedure")}
          className="input_field_create"
        />
        {/* <span className="number_input_unit">PSI</span> */}
        <br></br>
        {/* {errors.testingProcedure && <span className="required_text">This is required</span>} */}
      </div>}

      {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && <div className={errors.automatedHandling ? "form_error_div" : "form_div"}>
        <label>Cable used in automated handling or stripping process? <span>*</span></label>
        <Controller
          name="automatedHandling"
          rules={{ required: "This is required" }}
          defaultValue={{
            "label": "No",
            "value": "No"
          }}
          control={control}
          render={({ field }) => (
            <Select {...field} options={automatedHandlings} styles={controlStyles} placeholder="Select Category" />
          )}
        />
        <br></br>
        {errors.automatedHandling && <span className="required_text">This is required</span>}
      </div>}

      {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && automatedHandling?.value == "Yes" && <div className={errors.newColumnAutomated ? "form_error_div" : "form_div"}>
        <label>Automated Handling Details <span>*</span></label>
        <textarea
          type="textarea"
          {...register("newColumnAutomated", { required: true })}
        />
        <br></br>
        {errors.newColumnAutomated && <span className="required_text">This is required</span>}
      </div>}

      {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && <div className={errors.cableContactPoints ? "form_error_div" : "form_div"}>
        <label>Cable Contact points with external elements <span>*</span></label><br></br>
        <Controller
          name="cableContactPoints"
          control={control}
          rules={{ required: "This is required" }}
          render={({ field }) => (
            <Select
              {...field}
              className="multi_select_dropdown"
              options={cableContactPointList}
              isMulti={true}
              defaultValue={ticketData?.cableContactPoints}
              // value={selectedOptions}
              styles={DropdownControllers.multiControl}
              onChange={(selectedOptions) => {
                // Update the field value to ensure it syncs with React Hook Form
                field.onChange(selectedOptions);
              }}
              components={{ Option: CustomOption, MultiValue: CustomMultiValue }}
            />
          )}
        />
        <br></br>
        {errors.cableContactPoints && <span className="required_text">This is required</span>}
      </div>}

      {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && <div className={errors.sunLightRes ? "form_error_div" : "form_div"}>
        <label>Sun light resistant plastic?  <span>*</span></label>
        <Controller
          name="sunLightRes"
          control={control}
          rules={{ required: "This is required" }}
          render={({ field }) => (
            <Select {...field} options={sunLightResistancePlastic} styles={controlStyles} placeholder="Select Category" />
          )}
        />
        <br></br>
        {errors.sunLightRes && <span className="required_text">This is required</span>}
      </div>}
      {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && <div className={errors.gammaRaysSterilize ? "form_error_div" : "form_div"}>
        <label>Gamma Ray sterilize? <span>*</span></label>
        <Controller
          name="gammaRaysSterilize"
          rules={{ required: "This is required" }}
          control={control}
          render={({ field }) => (
            <Select {...field} options={gammaRays} styles={controlStyles} placeholder="Select Gamma Ray Sterilize" />
          )}
        />
        <br></br>
        {errors.sunLightRes && <span className="required_text">This is required</span>}
      </div>}

      {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && <div className={errors.ulListingRequired ? "form_error_div" : "form_div"}>
        <label>UL Listing required? <span>*</span></label>
        <Controller
          name="ulListingRequired"
          control={control}
          rules={{ required: "This is required" }}
          render={({ field }) => (
            <Select
              {...field}
              className="multi_select_dropdown"
              options={UlListing}
              isMulti={true}
              defaultValue={ticketData?.ulListingRequired}
              // value={selectedOptions}
              styles={DropdownControllers.multiControl}
              onChange={(selectedOptions) => {
                field.onChange(selectedOptions);
              }}
              // defaultValue={defaultValue}
              components={{ Option: CustomOption, MultiValue: CustomMultiValue }}
            // formatCreateLabel={(inputValue) => `Create "${inputValue}"`}  
            />
          )}
        />
        <br></br>
        {errors.ulListingRequired && <span className="required_text">This is required</span>}
      </div>}

      <hr className="field_seperator" />

      {/* <div className={errors.ticketStatus ? "form_error_div" : "form_div"}>
        <label>Ticket Status <span>*</span></label>
        <Controller
          name="ticketStatus"
          rules={{ required: "This is required" }}
          control={control}
          render={({ field }) => (
            <Select {...field} options={ticketStatus} styles={controlStyles} placeholder="Ticket Status" />
          )}
          defaultValue={{
            "id": 2,
            "value": "Not started",
            "label": "Not started"
          }}
        />
        <br></br>
        {errors.ticketStatus && <span className="required_text">This is required</span>}
      </div> */}

      <div className={errors.description ? "form_error_div" : "form_div"}>
        <label>Description <span>*</span></label>
        <Controller
          name="description"
          rules={{ required: "This is required" }}
          control={control}
          render={({ field }) => (
            <RichText value={field.value} onChange={field.onChange} />
          )}
        />
        <br></br>
        {errors.description && <span className="required_text">This is required</span>}
      </div>

      <div className={errors.customerName ? "form_error_div" : "form_div"}>
        <label>Customer Name <span>*</span></label>
        <Controller
          name="customerName"
          rules={{ required: "This is required" }}
          control={control}
          render={({ field }) => (
            <Select {...field} options={customerNameList} styles={controlStyles} placeholder="Select Customer Name" />
          )}
        />
        <br></br>
        {errors.customerName && <span className="required_text">This is required</span>}
      </div>

      <div className="form_sub_div">
        <div className={"form_div"}>
          <label>Enter Assigned Part Numbers (Enter a value and press enter to select)</label>
          <Controller
            name="assignedPartNumber"
            control={control}
            render={({ field }) => (
              <CreatableSelect
                {...field}
                inputValue={inputValue}
                styles={DropdownControllers.multiControl}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={(selectedOptions) => {
                  field.onChange(selectedOptions);
                  setKeywordsValue(selectedOptions); // Optional: Your custom handler
                }}
                // onChange={(newValue) => setKeywordsValue(newValue)}
                onInputChange={(newValue) => {
                  if (isMobile) {
                    if (newValue != "") {
                      setInputValue(newValue);
                    } else if (inputValue.length == 1 && newValue == "") {
                      setInputValue(newValue);
                    }
                  } else {
                    setInputValue(newValue);
                  }
                }}
                onKeyDown={handleKeyDown}
                placeholder="Enter Assigned Part Numbers"
                value={keywordsValue}

              />
            )}
          />
          <br></br>
        </div>
        <span className="required_text">{validationMsg}</span>
        <div className="number_items">
          <div className="number_item">
            <div className="form_div">
              <label>Quote Part Required</label>
              <input
                type="number"
                name="quotePartRequired"
                className="input_field_create"
                {...register("quotePartRequired")}
              />
              <br></br>
            </div>

            <div className={"form_div"}>
              <label>Order Part Required</label>
              <input
                type="number"
                className="input_field_create"
                name="orderPartRequired"
                {...register("orderPartRequired")}
              />
              <br></br>
            </div>
          </div>

          <div className="number_item">
            <div className={"form_div"}>
              <label>Word Specs Required</label>
              <input
                type="number"
                className="input_field_create"
                name="wordSpecsRequired"
                {...register("wordSpecsRequired")}
              />
              <br></br>
            </div>

            <div className={"form_div"}>
              <label>CAD Drawings Required</label>
              <input
                type="number"
                className="input_field_create"
                {...register("cadDrawingsRequired")}
              />
              <br></br>
            </div>
          </div>
        </div>
      </div>
      <div className={"form_div"}>
        <div className="price_container">
          <label>Price</label><br />
          <div className="input_with_icon_price">
            <input
              name="price"
              type="number"
              {...register("price")}
              className="price_input"
            />
            <span className="icon_container">
              <AttachMoneyOutlinedIcon sx={{ backgroundColor: "#373737", color: "#ef6e35", left: "0", position: "absolute", height: "25px", width: "25px", borderRadius: "5px", margin: "8px", marginLeft: "9px", marginTop: "3px" }} />
            </span>
          </div>
        </div>
      </div>
      <div className="form_div">
        <label>Footage</label><br></br>
        <input
          type="text"
          className="input_field_create"
          {...register("footage")}
          placeholder="Enter Footage"
          onChange={handleFootageChange}
          value={footageValue}
        />
        <span className="number_input">ft</span>
      </div>

      <div className="form_div">
        <label>Emails to Notify</label>
        <Controller
          name="emailsToNotify"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              className="multi_select_dropdown"
              options={groupEmployeData}
              isMulti={true}
              defaultValue={ticketData?.emailsToNotify}
              // value={selectedOptions}
              styles={DropdownControllers.multiControl}
              onChange={(selectedOptions) => {
                // Update the field value to ensure it syncs with React Hook Form
                field.onChange(selectedOptions);
              }}
              // defaultValue={defaultValue}
              components={{ Option: CustomOption, MultiValue: CustomMultiValue }}
            // formatCreateLabel={(inputValue) => `Create "${inputValue}"`}  
            />
            // <MultiSelectDropdown
            //   {...field}
            //   options={groupEmployeData}
            //   displayValue="label"
            // />
          )}
        />
      </div>

      <div className="form_div">
        <label>Attachments</label>
        <input
          type="file"
          id="attachments"
          name="attachments"
          multiple
          {...register("attachments", {
            onChange: handleUploadImage
          })}
          style={{ display: "none" }} // Hides the default input
        />
        <label htmlFor="attachments" className="custom_file_upload">
          <div>
            <CloudDoneOutlinedIcon />
            <p className="upload_text">Choose a file or drag & drop it here (50MB)</p>
            <div className="browse_btn">
              <span className="browse_btn_text">Browse File</span>
            </div>
          </div>
        </label>
      </div>
      {
        attachmentLoading &&
        <>
          <CircularProgress sx={{ marginTop: "30px", marginLeft: "20%" }} />
        </>
      }
      {
        attachmentsList?.map((item, index) => {
          return (
            <div className="selected_files">
              <div onClick={() => handleAttachmentClick(index)}>
                <img src={pdfIcon} alt="" width={isMobile ? 40 : 60} height={isMobile ? 50 : 70} className="upload_document" />
              </div>
              <div className="selected_files_text" onClick={() => handleAttachmentClick(index)}>
                <div>
                  <span className="file_details">{item}</span>
                  <br></br>
                  {/* <CheckCircleRoundedIcon sx={{ color: "white", height: "15px", width: "15px" }} />  */}
                  {/* <span className="file_details">Completed</span> */}
                </div>
              </div>
              <div className="clear_icon">
                <ClearOutlinedIcon sx={{ color: "white", right: "0px" }} onClick={() => deleteAttachment(item, index)} />
              </div>
            </div>
          );
        })
      }
      {/* <AttachedFile />
      <AttachedFile /> */}
      <hr className="field_seperator" />

      <div className="form_div">
        <div className="btn_main_div">
          <div className="btn_div">
            <button id="cancel_btn" type="button" onClick={() => { reset(); dispatch(setOpenCreateModal(false)); }}>Cancel</button>
            <button id="create_ticket" type="submit" className="create_ticket">Create</button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreateTicketForm;