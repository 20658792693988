const isMobile = window.innerWidth <= 768;

const DropdownControllers = {
  controlStyles: {
    control: (provided) => ({
      ...provided,
      boxShadow: "none",
      border: "none",
      backgroundColor: "rgb(39, 39, 39)",
      color: "#fff",
      width: isMobile?"275px":"100%",
      marginTop: "10px",
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: "rgb(39, 39, 39)",
      color: "#fff",
      width: isMobile?"400px":"auto",
      border: "none",
      boxShadow: "none",
      cursor: "pointer",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff",
    }),
    input: (provided) => ({
      ...provided,
      color: "#fff",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#ccc",
    }),
  },
  controlStylesDropdown: {
    control: (provided) => ({
      ...provided,
      boxShadow: "none",
      border: "none",
      backgroundColor: "rgb(39, 39, 39)",
      color: "#fff",
      width: isMobile?"275px":"400px",
      marginTop: "10px",
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: "rgb(39, 39, 39)",
      color: "#fff",
      width: isMobile?"400px":"auto",
      border: "none",
      boxShadow: "none",
      cursor: "pointer",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff",
    }),
    input: (provided) => ({
      ...provided,
      color: "#fff",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#ccc",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "black",
      ":hover": {
        backgroundColor: "#ef6e35",
        color: "white",
      },
    }),
  },
  controlStylesFilter: {
    control: (provided) => ({
      ...provided,
      boxShadow: "none",
      border: "none",
      backgroundColor: "rgb(39, 39, 39)",
      color: "#fff",
      width: "180px",
      marginTop: "7px",
      fontSize: "14px",
      // height: "2px"
      color: "#fff",
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: "rgb(39, 39, 39)",
      color: "#fff",
      width: "180px",
      border: "none",
      boxShadow: "none",
      cursor: "pointer",
      color: "#fff",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff",
    }),
    input: (provided) => ({
      ...provided,
      color: "#fff",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#ccc",
    }),
  },
  controlStylesIdentifier: {
    control: (provided) => ({
      ...provided,
      boxShadow: "none",
      border: "none",
      backgroundColor: "rgb(39, 39, 39)",
      color: "#fff",
      width: "150px",
      marginTop: "7px",
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: "rgb(39, 39, 39)",
      color: "#fff",
      width: "150px",
      border: "none",
      boxShadow: "none",
      cursor: "pointer",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff",
    }),
    input: (provided) => ({
      ...provided,
      color: "#fff",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#ccc",
    }),
  },
  multiControl :{
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      border: "none",
      backgroundColor: "rgb(39, 39, 39)",
      color: "#fff",
      width: "100%",
      marginTop: "10px"
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "rgb(39, 39, 39)",
      color: "#fff",
      width: "100%",
      border: "none",
      boxShadow: "none",
      cursor: "pointer",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff",
    }),
    input: (provided) => ({
      ...provided,
      color: "#fff",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#ccc",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "black",
      ":hover": {
        backgroundColor: "#ef6e35",
        color: "white",
      },
    }),
  }
};
export default DropdownControllers;