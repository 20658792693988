import CustomHeader from "../dashboard/CustomHeader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DnsIcon from '@mui/icons-material/Dns';
import ActionGroupRenderer from "../dashboard/ActionGroupRenderer";
const isMobile = window.innerWidth <= 768;

const groupColumns = [
    {
        field: "GroupID",
        headerComponent: CustomHeader,
        headerComponentParams: { icon: <InfoOutlinedIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Group ID" },
        filter: "agNumberColumnFilter",
        minWidth: isMobile ? 40 : 250,
        filterParams: {
            defaultToNothingSelected: true,
            // default: "Urgent",  
        },
        cellStyle: (params) => {
            return {
                backgroundColor: "#222222",
                color: "white",
                cursor: "pointer"
            };
        },
        pinned: true,
        // hide: isMobile,
    },
    {
        field: "GroupName",
        headerComponent: CustomHeader,
        headerComponentParams: { icon: <DnsIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Group Name" },
        filter: isMobile ? "" : "agSetColumnFilter",
        // minWidth: isMobile ? 40 : 250,
        filterParams: {
            defaultToNothingSelected: true,
            // default: "Urgent",  
        },
        cellStyle: (params) => {
            return {
                backgroundColor: "#222222",
                color: "white",
                cursor: "pointer"
            };
        },
        editable: true,
        // hide: isMobile,
    },
    {
        headerName: 'Delete',
        field: 'actions',
        cellRenderer: ActionGroupRenderer,
        flex: 1, // Adjust width
        // minWidth: 150,
        resizable: true,
        sortable: false,
        filter: false,
        cellStyle: (params) => {
            return {
                backgroundColor: "#222222",
                color: "white",
            };
        },
        // pinned: true
    },
]
export default groupColumns;