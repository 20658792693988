export const SET_INVOLVE_TICKETS = 'SET_INVOLVE_TICKETS';
const SET_TICKET_STATUS = "SET_TICKET_STATUS";
const TICKET_STATUS_FILTER = "TICKET_STATUS_FILTER";
const TICKET_STATUS_MAP = "TICKET_STATUS_MAP";
const PRIORITY_MAP = "PRIORITY_MAP";
const WORK_TYPE_MAP = "WORK_TYPE_MAP";
const CATEGORY_TYPE_MAP = "CATEGORY_TYPE_MAP";
const ASSIGNED_TO_PERSON_MAP = "ASSIGNED_TO_PERSON_MAP";
const ASSIGNED_TO_GROUP_MAP = "ASSIGNED_TO_GROUP_MAP";
const REASON_FOR_NO_QUOTE_MAP = "REASON_FOR_NO_QUOTE_ID";
const MISTAKE_CODE_MAP = "MISTAKE_CODE_MAP";
const FILTERED_DATA_FOR_TABLE = "FILTERED_DATA_FOR_TABLE";
const FILTER_OPTIONS = "FILTER_OPTIONS";
const OPEN_EDIT_MODAL = "OPEN_EDIT_MODAL";
const OPEN_CREATE_MODAL = "OPEN_CREATE_MODAL";
const MISTAKE_CODE = "MISTAKE_CODE";
const REASON_FOR_NO_QUOTE = "REASON_FOR_NO_QUOTE";
const CONTACT_POINTS = "CONTACT_POINTS";
const UL_LISTINGS = "UL_LISTINGS";
const TICKET_STATUS = "TICKET_STATUS";
const CATEGORIES = "CATEGORIES";
const PRIORITIES = "PRIORITIES";
const WORK_TYPE = "WORK_TYPE";
const GROUP_EMPLOYEE = "GROUP_EMPLOYEE";
const TICKET_DATA = "TICKET_DATA";
const INVOLVE_TICKET_MAP = "INVOLVE_TICKET_MAP";
const ROW_DATA_APPLIED = "ROW_DATA_APPLIED";
const FEEDBACK_MODAL = "FEEDBACK_MODAL";
const CUSTOMER_NAME_MAP = "CUSTOMER_NAME_MAP";
const CUSTOMER_NAME = "CUSTOMER_NAME";
const EDIT_TICKET = "EDIT_TICKET";
const EDIT_TICKET_RESULT = "EDIT_TICKET_RESULT";
const ACTIVE_FILTERS = "ACTIVE_FILTERS";
const SELECTED_USERS = "SELECTED_USERS";
const COMPLETE_DATA = "COMPLETE_DATA";
const UPLOAD_URL_TICKET = "UPLOAD_URL_TICKET";
const UPLOAD_URL_TEMP = "UPLOAD_URL_TEMP";
const GET_ATTACHMENT = "GET_ATTACHMENT";
const DOWNLOAD_ATTACHMENT = "DOWNLOAD_ATTACHMENT";
const GROUP_TO_EMPLOYE_MAP = "GROUP_TO_EMPLOYE_MAP";
const GROUP_EMPLOYEE_RESPONSE = "GROUP_EMPLOYEE_RESPONSE";
const IS_LOADING = "IS_LOADING";
const QUICK_FILTER_TEXT = "QUICK_FILTER_TEXT";
const OPEN_REVISION_MODAL = "OPEN_REVISION_MODAL";
const DELETE_FILE = "DELETE_FILE";
const EMAILS_TO_NOTIFY = "EMAILS_TO_NOTIFY";
const EMAIL_TO_NOTIFY_LOADING = "EMAIL_TO_NOTIFY_LOADING";
const FILE_TO_REMOVE = "FILE_TO_REMOVE";
const ADD_COMMENTS = "ADD_COMMENTS";
const GET_COMMENTS = "GET_COMMENTS";
const COMMENT_IS_LOADING = "COMMENT_IS_LOADING";
const LOGIN_PAYLOAD = "LOGIN_PAYLOAD";
const LOGIN_TOKEN = "LOGIN_TOKEN";
const IS_PROTECTED = "IS_PROTECTED";
const IS_LOGGEDIN = "IS_LOGGEDIN";
const TICKET_HISTORY = "TICKET_HISTORY";
const IS_HISTORY_LOADING = "IS_HISTORY_LOADING";
const USER_EMAIL_MAP = "USER_EMAIL_MAP";
const REVISION_TICKET = "REVISION_TICKET"; 
const PARENT_TICKET = "PARENT_TICKET";
const LINEAGE_DATA = "LINEAGE_DATA";
const TICKET_TITLE_MAP = "TICKET_TITLE_MAP";
const NOTIFICATIONS_LIST = "NOTIFICATIONS_LIST";
const MARK_NOTIFICATION = "MARK_NOTIFICATION";
const NOTIFICATION_STATUS_MAP = "NOTIFICATION_STATUS_MAP";
const FEEDBACK_RESULT = "FEEDBACK_RESULT";
const EMAILS_TO_NOTIFY_MAP = "EMAILS_TO_NOTIFY_MAP";
const EMAILS_TO_NOTIFY_MAP_DATA = "EMAILS_TO_NOTIFY_MAP_DATA";
const DOWNLOAD_FILE_TEMP = "DOWNLOAD_FILE_TEMP";
const ATTACHMENT_LOADING  = "ATTACHMENT_LOADING";
const PUTSIGNED_SUCCESS = "PUTSIGNED_SUCCESS";
const IS_BACKDROP_LOADING = "IS_BACKDROP_LOADING";
const CREATE_DATA = "CREATE_DATA";
const ENTER_COMMENT = "ENTER_COMMENT";
const CURRENT_USER = "CURRENT_USER";
const EMPLOYEE_WORKLOAD = "EMPLOYEE_WORKLOAD";
const CATEGORIES_DATA = "CATEGORIES_DATA";
const ADD_NEW_CATEGORY = "ADD_NEW_CATEGORY";
const DELETE_CATEGORY = "DELETE_CATEGORY";
const UPDATE_CATEGORY = "UPDATE_CATEGORY";
const LOAD_UPDATE_CATEGORY = "LOAD_UPDATE_CATEGORY";
const CATEGORY_LOADING = "CATEGORY_LOADING";
const HAS_DASHBOARD_RUN = "HAS_DASHBOARD_RUN";
const EMPLOYEE_SKILLS = "EMPLOYEE_SKILLS";
const ADD_EMPLOYEE_SKILLS = "ADD_EMPLOYEE_SKILLS";
const DELETE_EMPLOYEE_SKILLS = "DELETE_EMPLOYEE_SKILLS";
const UPDATE_EMPLOYEE_SKILLS = "UPDATE_EMPLOYEE_SKILLS";
const GROUP_TABLE_DATA = "GROUP_TABLE_DATA";
const CREATE_GROUP_DATA = "CREATE_GROUP_DATA";
const DELETE_GROUP_DATA = "DELETE_GROUP_DATA";
const UPDATE_GROUP_DATA = "UPDATE_GROUP_DATA";
const EMPLOYEE_TABLE_DATA = "EMPLOYEE_TABLE_DATA";
const ADD_EMPLOYEE_GROUPS = "ADD_EMPLOYEE_GROUPS";
const DELETE_EMPLOYEE_GROUP = "DELETE_EMPLOYEE_GROUP";
const UPDATE_EMPLOYEE_GROUP = "UPDATE_EMPLOYEE_GROUP";

export { 
    SET_TICKET_STATUS, 
    TICKET_STATUS_FILTER, 
    TICKET_STATUS_MAP, 
    PRIORITY_MAP,
    WORK_TYPE_MAP,
    CATEGORY_TYPE_MAP,
    ASSIGNED_TO_PERSON_MAP,
    ASSIGNED_TO_GROUP_MAP,
    REASON_FOR_NO_QUOTE_MAP,
    MISTAKE_CODE_MAP,
    FILTERED_DATA_FOR_TABLE,
    FILTER_OPTIONS,
    OPEN_EDIT_MODAL,
    OPEN_CREATE_MODAL,
    MISTAKE_CODE,
    REASON_FOR_NO_QUOTE,
    CONTACT_POINTS,
    UL_LISTINGS,
    TICKET_STATUS,
    CATEGORIES,
    PRIORITIES,
    WORK_TYPE,
    GROUP_EMPLOYEE,
    TICKET_DATA,
    INVOLVE_TICKET_MAP,
    ROW_DATA_APPLIED,
    FEEDBACK_MODAL,
    CUSTOMER_NAME_MAP,
    CUSTOMER_NAME,
    EDIT_TICKET,
    EDIT_TICKET_RESULT,
    ACTIVE_FILTERS,
    SELECTED_USERS,
    COMPLETE_DATA,
    UPLOAD_URL_TICKET,
    UPLOAD_URL_TEMP,
    GET_ATTACHMENT,
    DOWNLOAD_ATTACHMENT,
    GROUP_EMPLOYEE_RESPONSE,
    GROUP_TO_EMPLOYE_MAP,
    IS_LOADING,
    QUICK_FILTER_TEXT,
    OPEN_REVISION_MODAL,
    DELETE_FILE,
    EMAILS_TO_NOTIFY,
    EMAIL_TO_NOTIFY_LOADING,
    FILE_TO_REMOVE,
    ADD_COMMENTS,
    GET_COMMENTS,
    COMMENT_IS_LOADING,
    LOGIN_PAYLOAD,
    LOGIN_TOKEN,
    IS_PROTECTED,
    IS_LOGGEDIN,
    TICKET_HISTORY,
    IS_HISTORY_LOADING,
    USER_EMAIL_MAP,
    REVISION_TICKET,
    PARENT_TICKET,
    LINEAGE_DATA,
    TICKET_TITLE_MAP,
    NOTIFICATIONS_LIST,
    MARK_NOTIFICATION,
    NOTIFICATION_STATUS_MAP,
    FEEDBACK_RESULT,
    EMAILS_TO_NOTIFY_MAP,
    EMAILS_TO_NOTIFY_MAP_DATA,
    DOWNLOAD_FILE_TEMP,
    ATTACHMENT_LOADING,
    PUTSIGNED_SUCCESS,
    IS_BACKDROP_LOADING,
    CREATE_DATA,
    ENTER_COMMENT,
    CURRENT_USER,
    EMPLOYEE_WORKLOAD,
    CATEGORIES_DATA,
    ADD_NEW_CATEGORY,
    DELETE_CATEGORY,
    UPDATE_CATEGORY,
    LOAD_UPDATE_CATEGORY,
    CATEGORY_LOADING,
    HAS_DASHBOARD_RUN,
    EMPLOYEE_SKILLS,
    ADD_EMPLOYEE_SKILLS,
    DELETE_EMPLOYEE_SKILLS,
    UPDATE_EMPLOYEE_SKILLS,
    GROUP_TABLE_DATA,
    CREATE_GROUP_DATA,
    DELETE_GROUP_DATA,
    UPDATE_GROUP_DATA,
    EMPLOYEE_TABLE_DATA,
    ADD_EMPLOYEE_GROUPS,
    DELETE_EMPLOYEE_GROUP,
    UPDATE_EMPLOYEE_GROUP,
 };
