import * as React from "react";
import Modal from "@mui/material/Modal";
import AddSkillForm from "../TicketOperations/AddSkillForm";

export default function AddSkillModal({ isSkillModal, handleSkillClose, selectedJobType, selectedCategory, selectedEmployees, selectedCategoryMap, isEdit, workTimes, employeeCategoryMap }) {
    return (
        <Modal
            open={isSkillModal}
            onClose={handleSkillClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflowY: "auto",
                height: "auto",
                marginTop: "20px",
                marginBottom: "20px",
                overflow: "hidden",
                // paddingTop:"40px"
                // width:"10px"
                // overflow: "auto", 
            }}
        >
            <AddSkillForm
                handleSkillClose={handleSkillClose}
                selectedJobType={selectedJobType}
                selectedCategory={selectedCategory}
                selectedEmployees={selectedEmployees}
                selectedCategoryMap={selectedCategoryMap}
                isEdit={isEdit}
                workTimes={workTimes}
                employeeCategoryMap={employeeCategoryMap}
            />
        </Modal>
    );
}
