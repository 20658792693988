import { AgGridReact } from "@ag-grid-community/react";
import { useEffect, useMemo, useRef, useState } from "react";
import categoryColumns from "../tableStructures/categoryColumns";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories, fetchCategoriesData, fetchEmployeeSkills, fetchWorkTypes, setCategoryLoading, setLoadUpdateCategory, updateCategoryChanges, updateEmployeeSkillsData } from "../../actions/ticketActions";
import AddIcon from '@mui/icons-material/Add';
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CategoryModal from "./CategoryModal";
import DataSaverOnOutlinedIcon from '@mui/icons-material/DataSaverOnOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import employeeSkillsColumns from "../tableStructures/employeeSkillsColumns";
import AddSkillModal from "./AddSkillModal";
const isMobile = window.innerWidth <= 768;


const ManageSkills = () => {
    const [gridApi, setGridApi] = useState(null);
    const [gridHeight, setGridHeight] = useState("");
    const [rowData, setRowData] = useState([]);
    const [quickFilterText, setQuickFilterText] = useState("");
    const [dirtyFields, setDirtyFields] = useState([]);
    const [isEdit, setIsEdit] = useState();
    const [categoryMapping, setCategoryMapping] = useState([]);
    const [selectedCategoryMap, setSelectedCategoryMap] = useState({});
    const [isSkillModal, setIsSkillModal] = useState(false);
    const [workTimes, setWorkTimes] = useState();
    const rowHeight = isMobile ? 42 : 80;
    const jobTypeListMap = { "CAD Drawing": 1, "Order Part": 2, "Word Spec": 3, "Quote Part": 4 }
    const [selectedJobTypeList, setSelectedJobTypeList] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState({});
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [employeeCategoryMap, setEmployeeCategoryMap] = useState([]);
    const { categoriesData, assignedToPersonMap, loadUpdateCategory, categoryLoading, employeeSkills, categoryTypeMap, categories, workTypes } = useSelector(state => state.tickets);
    const gridRef = useRef();
    const dispatch = useDispatch();
    const gridStyle = useMemo(
        () => ({
            height: rowData?.length > 0 ? gridHeight : "300px",
            maxHeight: isMobile ? gridHeight : "700px",
            width: "100%",
            // borderLeft: `2px solid ${colorcode}`,
            backgroundColor: isMobile ? "none" : "#171717",
            overflowX: "auto",
        }),
        [gridHeight, rowData, categoriesData, quickFilterText]
    );
    const containerStyle = useMemo(
        () => ({
            width: "100%",
            height: "auto",
            minHeight: "200px",
        }),
        []
    );
    function mapSkillCategory(employeeSkills) {
        const result = employeeSkills?.data?.map(employee => {
            const categorizedSkills = {};

            employee.skills.forEach(skill => {
                if (!categorizedSkills[skill.CategoryID]) {
                    categorizedSkills[skill.CategoryID] = [];
                }
                categorizedSkills[skill.CategoryID].push({
                    id: jobTypeListMap[skill.job_type],
                    skillId: skill.id,
                    value: skill.job_type,
                    label: skill.job_type
                });
            });
            return (categorizedSkills);
        });
        setCategoryMapping(result);
    }
    useEffect(() => {
        if (employeeSkills?.data?.length > 0) {
            mapSkillCategory(employeeSkills);
        }
    }, [employeeSkills]);
    useEffect(() => {
        if (categoryMapping?.length > 0) {
            const temp = {};
            for (let i = 0; i < employeeSkills?.data?.length; i++) {
                // temp[]
                if (!temp?.employeeSkills?.data[i]?.EmployeeID) {
                    temp[employeeSkills?.data[i]?.EmployeeID] = categoryMapping[i];
                }
            }
            setEmployeeCategoryMap(temp);
        }
    }, [categoryMapping]);

    useEffect(() => {
        if (employeeSkills?.data?.length > 0 && categoryMapping?.length > 0) {
            const processedData = [];
            employeeSkills?.data?.forEach((employee, index) => {
                const keys = Object.keys(categoryMapping[index]);
                const firstKey = keys[0];
                for (let key in categoryMapping[index]) {
                    if (categoryMapping[index].hasOwnProperty(key)) {
                        processedData.push({
                            id: key,
                            index: index,
                            EmployeeID: key === firstKey ? assignedToPersonMap[employee.EmployeeID] : "",
                            emp_id: { id: employee.EmployeeID, label: assignedToPersonMap[employee.EmployeeID], value: assignedToPersonMap[employee.EmployeeID] },
                            work_time: key === firstKey ? employee.work_time : "",
                            work_times: employee.work_time,
                            skill_id: key,
                            category_id: categoryTypeMap[key] + ` (${key})`,
                            job_type: categoryMapping[index][key],
                            RowIndex: `${index} ${key}`,
                            TotalSkills: keys.length,
                            categories: categories,
                        });
                    }
                }


            });
            setRowData(processedData);
        }
    }, [categoryMapping]);

    const onGridReady = (params) => {
        setGridApi(params.api);
        gridRef.current = params.api;
        setTimeout(() => {
            params.api.onFilterChanged();
        }, 500);
        params.api.onFilterChanged();
    };

    useEffect(() => {
        // dispatch(setCategoryLoading(true));
        dispatch(fetchEmployeeSkills());
    }, [])

    const onCellValueChanged = (params) => {
        const { colDef, oldValue, newValue, data } = params;

        if (oldValue !== newValue) {
            setDirtyFields(
                (prevDirtyFields) => {
                    const index = prevDirtyFields.findIndex((field) => field.id === data.skill_id);
                    if (index !== -1) {
                        // Update the existing object at the found index
                        const updatedFields = [...prevDirtyFields];
                        updatedFields[index] = {
                            ...updatedFields[index],
                            [colDef.field]: colDef.field === "category_id" ? newValue.match(/\((.*?)\)/)[1] : newValue,
                        };
                        return updatedFields;
                    } else {
                        // Add a new entry if no match is found
                        return [
                            ...prevDirtyFields,
                            {
                                id: data.skill_id,
                                [colDef.field]: colDef.field === "category_id" ? newValue.match(/\((.*?)\)/)[1] : newValue,
                            },
                        ];
                    }
                }
            );
        }
    };

    useEffect(() => {
        if (gridApi) {
            gridApi.setGridOption("quickFilterText", quickFilterText);
            gridApi.onFilterChanged();
            const displayedRows = [];
            gridApi?.forEachNodeAfterFilterAndSort((node) => {
                displayedRows.push(node.data);
            });
            const rowCount = displayedRows.length;
            const calculatedHeight = (rowCount) * (rowHeight);
            setGridHeight(`${calculatedHeight}px`);
        }
    }, [gridApi, employeeSkillsColumns, isMobile, rowData, quickFilterText, rowHeight, categoriesData]);
    const handleAddSkills = () => {
        setSelectedCategory(false);
        setSelectedJobTypeList(false);
        setSelectedEmployees(false);
        setIsSkillModal(true);
        setIsEdit(false);
    }
    const handleSkillClose = () => {
        setIsSkillModal(false)
    }
    const handleSkillUpdate = () => {
        dispatch(setLoadUpdateCategory(true));
        dispatch(updateEmployeeSkillsData(dirtyFields));
    }
    useEffect(() => {
        dispatch(fetchCategories());
        dispatch(fetchWorkTypes());
    }, []);
    const onRowClick = (event) => {
        setSelectedJobTypeList(event?.data?.job_type);
        setSelectedEmployees(event?.data?.emp_id);
        setSelectedCategoryMap(categoryMapping[event?.data?.index]);
        setWorkTimes(event?.data?.work_times);
        setIsEdit(true);
        const str = event?.data?.category_id;
        const match = str.match(/\((\d+)\)/);
        const categoryID = match[1];
        setSelectedCategory({ id: categoryID, label: categoryTypeMap[categoryID], value: categoryTypeMap[categoryID] });
        setIsSkillModal(true);
    }

    return (
        <>
            <div className="table_main">
                <div className="action_container">
                    <div className="search_container_category">
                        <input type="text" placeholder="Search..." onChange={(e) => setQuickFilterText(e.target.value)} className="searchbar_category"/>
                        <SearchOutlinedIcon className="search_icon" />
                    </div>
                    <div className="btn_div">
                        <button className="create_btn"><div className="btn_content" onClick={handleSkillUpdate}><DataSaverOnOutlinedIcon className="add_icon" loading /><span className="btn_text">Save Changes</span></div></button>
                        <button className="create_btn"><div className="btn_content" onClick={handleAddSkills}><AddIcon className="add_icon" /> <span className="btn_text">Add Skill</span></div></button>
                    </div>
                </div>
                <div className={`ag-theme-quartz-dark`} style={containerStyle}>
                    <div style={gridStyle}>
                        <AgGridReact
                            // ref={gridRef}
                            rowData={rowData}
                            columnDefs={employeeSkillsColumns}
                            defaultColDef={{
                                resizable: true,
                                sortable: true,
                                autoSize: true,
                                filter: true,
                                flex: 1,
                                minWidth: 100,
                                // cellStyle: { backgroundColor: "#272727", color: "white" },
                                headerClass: "custom-header",
                                width: "100%",
                            }}
                            // headerHeight={window.innerWidth <= 768 ? 0 : 40}
                            domLayout={Number(gridHeight.slice(0, -2)) < 800 ? "autoHeight" : "normal"}
                            rowSelection="multiple"
                            onGridReady={onGridReady}
                            // rowHeight={isMobile ? "80px" : "42px"}
                            suppressDragLeaveHidesColumns={false}
                            onCellValueChanged={onCellValueChanged}
                            suppressRowClickSelection={true}
                            pagination={true}
                            paginationPageSize={20}
                            suppressRowTransform={true}
                            onRowClicked={(event) => {
                                if (event.api.getFocusedCell() && event.api.getFocusedCell().column.getColId() === "actions") {
                                    return;
                                }
                                onRowClick(event);
                            }}
                            loadingOverlayComponent={() => (
                                categoryLoading ? <CircularProgress /> :
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                        backgroundColor: "#272727",
                                        color: "white",
                                        width: "100%"
                                    }}>
                                        No data found
                                    </div>
                            )}
                        />
                    </div>
                </div>
            </div>
            {loadUpdateCategory && <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={loadUpdateCategory}
            >
                <CircularProgress color="inherit" />
            </Backdrop>}
            <AddSkillModal
                isSkillModal={isSkillModal}
                handleSkillClose={handleSkillClose}
                selectedJobType={selectedJobTypeList}
                selectedCategory={selectedCategory}
                selectedEmployees={selectedEmployees}
                selectedCategoryMap={selectedCategoryMap}
                isEdit={isEdit}
                workTimes={workTimes}
                employeeCategoryMap={employeeCategoryMap}
            />
        </>
    );
}
export default ManageSkills;
