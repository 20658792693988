import FilterOptions from "./FilterOptions";
import "./index.css";
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import ActiveFilters from "./Active Filters";
import InProgress from "./InProgress";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { setOpenCreateModal } from "../../actions/ticketActions";

const MainBoard = () => {
    const dispatch = useDispatch();
    const isMobile = useMediaQuery("(max-width:768px)");
    const [loadInProgress, setLoadInProgress] = useState(true);
    const [loadUrgent, setLoadUrgent] = useState(true);
    const handleCreateTicket = () => {
        dispatch(setOpenCreateModal(true));
    };


    return (
        <>
            <div className="main_board">
                <div className="tickets_board">
                    <div className="ticket_items">
                        <div className="ticket_item_active">
                            <span className="item_text">All Tickets/Open Tickets</span>
                            <p className="tickets_text">Tickets in queue</p>
                        </div>
                        {/* <div className="ticket_item">
                            <span className="item_text">All Tickets/Open Tickets</span>
                            <p className="tickets_text">Queue B</p>
                        </div>
                        <div className="ticket_item">
                            <span className="item_text">All Tickets/Open Tickets</span>
                            <p className="tickets_text">Queue C</p>
                        </div> */}
                    </div>
                    <div className="btn_div">
                        <button className="create_btn"><div className="btn_content" onClick={handleCreateTicket}><CreateNewFolderOutlinedIcon className="add_icon" /> <span className="btn_text">Create Ticket</span></div></button>
                    </div>

                </div>
                <div className="table_div">
                    <FilterOptions />
                    <ActiveFilters />
                    {!isMobile && <InProgress title="IN PROGRESS" colorcode="#2ca248" statusTable="In Progress" loading={loadInProgress} setLoading={setLoadInProgress} />}
                    {!isMobile && <InProgress colorcode="red" statusTable="priorityStatus" loading={loadUrgent} setLoading={setLoadUrgent} />}
                    {isMobile && <InProgress statusTable="allData" loading={loadUrgent} setLoading={setLoadUrgent} />}
                    {/* <InProgress title="NORMAL" colorcode="white" statusTable="Normal" loading={loadNormal} setLoading={setLoadNormal}/> */}
                    {/* <InProgress title="IN REVIEW" colorcode="white" statusTable="In Review"/>
                    <InProgress title="Hold for Sales Response" colorcode="white" statusTable="Hold for Sales Response"/>
                    <InProgress title="COMPLETE" colorcode="white" statusTable="Complete"/> */}
                </div>
            </div>
        </>
    );
};
export default MainBoard;