let modifyListToString = (arr) => arr?.map(item => item.value)?.join(", ");

const EditPayload = (data) => {
  let ulListingModifiedList = [];
  let groupEmployeId = [];

  if (data?.ulListingRequired) {
    ulListingModifiedList = data?.ulListingRequired.map(item => item?.id);
  }

  if (data?.emailsToNotify) {
    groupEmployeId = data?.emailsToNotify.map(item =>
      item?.type === "group"
        ? { "GroupID": item?.id }
        : { "EmployeeID": item?.id }
    );
  }
  let processCableModifiedList;
  if (data?.processApplication) {
    processCableModifiedList = modifyListToString(data?.processApplication, "");
  }
  let cableContactPointsModifiedList;
  if (data?.cableContactPoints) {
    cableContactPointsModifiedList = modifyListToString(data?.cableContactPoints, "");
  }
  let transformedData = {};

  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      switch (key) {
        case "title":
          transformedData["Title"] = data[key];
          break;

        case "workType":
          transformedData["WorkTypeID"] = Number(data[key]?.id);
          break;

        case "priority":
          transformedData["PriorityID"] = Number(data[key]?.id);
          break;

        case "category":
          transformedData["CategoryID"] = Number(data[key]?.id);
          break;
        case "late_reason":
          transformedData["ReasonForLate"] = data[key];
          break;
        case "mistake_code":
          transformedData["MistakeCodeID"] = Number(data[key]?.id);
          break;
        case "price":
          transformedData["Price"] = data[key];
          break;
        case "CustomerName":
          transformedData["CustomerName"] = data[key]?.id;
          break;
        case "notes_sales":
          transformedData["Notes"] = data[key];
          break;
        case "noquote_reason":
          transformedData["ReasonForNoQuoteID"] = Number(data[key]?.id);
          break;
        case "isSample":
          transformedData["is_sample_available"] = data[key]?.value === "Yes" ? true : false;
          break;
        case "assigned_to":
          transformedData["AssignedToPersonID"] = data[key]?.id;
          break;
        case "getSample":
          transformedData["can_get_sample"] = data[key]?.value === "Yes" ? true : false;
          break;

        case "isProcess":
          transformedData["need_to_see_process"] = data[key]?.value === "Yes" ? true : false;
          break;

        case "pullForceNeed":
          transformedData["specific_pull_force_required"] = data[key]?.value === "Yes" ? true : false;
          break;

        case "newColumnPullForce":
          transformedData["pull_force_value"] = Number(data[key]);
          break;

        case "automatedHandling":
          transformedData["automated_handling_process"] = data[key]?.value === "Yes" ? true : false;
          break;

        case "newColumnAutomated":
          transformedData["automated_handling_details"] = data[key];
          break;

        case "sunLightRes":
          transformedData["sun_lite_resistant_plastic"] = data[key]?.value === "Yes" ? true : false;
          break;

        case "gammaRaysSterilize":
          transformedData["gamma_ray_sterilize"] = data[key]?.value === "Yes" ? true : false;
          break;

        case "ticketStatus":
          transformedData["TicketStatusID"] = Number(data[key]?.id);
          break;

        case "customerName":
          transformedData["CustomerID"] = Number(data[key]?.id);
          break;

        case "assignedPartNumber":
          transformedData["AssignedPartNumber"] = data[key];
          break;

        case "quotePartRequired":
          transformedData["QuotePartRequired"] = Number(data[key]);
          break;

        case "orderPartRequired":
          transformedData["OrderPartRequired"] = Number(data[key]);
          break;

        case "wordSpecsRequired":
          transformedData["WordSpecsRequired"] = Number(data[key]);
          break;

        case "cadDrawingsRequired":
          transformedData["CADDrawingsRequired"] = Number(data[key]);
          break;

        case "footage":
          transformedData["Footage"] =  Number(data[key].split(",").join(""));
          break;

        case "attachments":
          transformedData["attachments"] = data[key];
          break;

        case "processCableModifiedList":
          transformedData["process_cable_used_in"] = processCableModifiedList || [];
          break;

        case "cableContactPointsModifiedList":
          transformedData["contact_points_with_elements"] = cableContactPointsModifiedList || [];
          break;

        case "ulListingRequired":
          transformedData["ul_listing_required"] = ulListingModifiedList || [];
          break;

        case "emailsToNotify":
          transformedData["emailsToNotify"] = groupEmployeId || [];
          break;
        case "description":
          transformedData["Description"] = data[key];
          break;
        case "testingRequired":
          transformedData["SpecialTesting"] = data[key]?.value === "Yes" ? true : false;
          break;
        case "testingProcedure":
          transformedData["SpecialTestingText"] = data[key];
          break;
        default:
          transformedData[key] = data[key];
      }
    }
  }

  return transformedData;
}

export default EditPayload;
