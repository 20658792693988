import CustomHeader from "../dashboard/CustomHeader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DnsIcon from '@mui/icons-material/Dns';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import CustomDropdownEditor from "../dashboard/CustomDropdownEditor";
import ActionCellRenderer from "../dashboard/ActionCellRenderer";
const isMobile = window.innerWidth <= 768;

const categoryColumns = [
    {
        field: "CategoryID",
        headerComponent: CustomHeader,
        headerComponentParams: { icon: <InfoOutlinedIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Category ID" },
        filter: "agNumberColumnFilter",
        minWidth: isMobile ? 40 : 250,
        filterParams: {
            defaultToNothingSelected: true,
            // default: "Urgent",  
        },
        cellStyle: (params) => {
            return {
                backgroundColor: "#222222",
                color: "white",
                cursor: "pointer"
            };
        },
        pinned: true,
        // hide: isMobile,
    },
    {
        field: "CategoryName",
        headerComponent: CustomHeader,
        headerComponentParams: { icon: <DnsIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Category Name" },
        filter: isMobile ? "" : "agSetColumnFilter",
        minWidth: isMobile ? 40 : "",
        filterParams: {
            defaultToNothingSelected: true,
            // default: "Urgent",  
        },
        cellStyle: (params) => {
            return {
                backgroundColor: "#222222",
                color: "white",
                cursor: "pointer"
            };
        },
        editable: true,
        // hide: isMobile,
    },
    {
        field: "autoassign",
        headerComponent: CustomHeader,
        headerComponentParams: { icon: <AutoFixHighOutlinedIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Auto Assign" },
        filter: isMobile ? "" : "agSetColumnFilter",
        minWidth: isMobile ? 40 : "",
        filterParams: {
            defaultToNothingSelected: true,
            // default: "Urgent",  
        },
        cellStyle: (params) => {
            return {
                backgroundColor: "#222222",
                color: "white",
                cursor: "pointer"
            };
        },
        editable: true,
        // hide: isMobile,
    },
    {
        field: "plant_manager",
        cellEditor: 'agSelectCellEditor',
        headerComponent: CustomHeader,
        headerComponentParams: { icon: <ManageAccountsOutlinedIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Plant Manager" },
        filter: isMobile ? "" : "agSetColumnFilter",
        minWidth: isMobile ? 40 : "",
        filterParams: {
            defaultToNothingSelected: true,
        },
        editable: true,
        cellEditorParams: (params) => {
            const dropdownValues = params?.data?.emp_list?.map(item => `${item.label} (${item.id})`) || [];
            
            return {
                values: dropdownValues,
                valueListMaxHeight: 220,
            };
        },
        cellStyle: (params) => {
            return {
                backgroundColor: "#222222",
                color: "white",
                cursor: "pointer",
            };
        },
        cellEditorFramework: CustomDropdownEditor,
        // hide: isMobile,
    },
    {
        headerName: 'Delete',
        field: 'actions',
        cellRenderer: ActionCellRenderer,
        // flex: 1,
        minWidth: isMobile ? 40 : "",
        // minWidth: 150,
        resizable: true,
        sortable: false,
        filter: false,
        cellStyle: (params) => {
            return {
                backgroundColor: "#222222",
                color: "white",
            };
        },
        // pinned: true
    },
]
export default categoryColumns;