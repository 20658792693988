import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar1 from "./components/navbar/Navbar1";
import Dashboard from "./components/dashboard/Dashboard";
import DashboardTable from "./components/dashboard/DashboardTable";
import CreateTicket from "./components/TicketOperations/CreateTicket";
import TicketForm from "./components/CreateTicket/TicketForm";
import SimpleGrid from "./components/dashboard/checkFilter";
import { useEffect, useRef } from "react";
import LoginPage from "./components/login/LoginPage";
import { useDispatch, useSelector } from "react-redux";
import { PDFPreview } from "./components/TicketOperations/Test";
import { Toaster } from 'react-hot-toast';
import { fetchCategories, fetchContactPoints, fetchCustomerNameData, fetchEmailsToNotifyMap, fetchGroupEmployeData, fetchMistakeCode, fetchPriorities, fetchReasonForNoQuote, fetchTicketStatus, fetchUlListings, fetchWorkTypes, setAssignedToGroupMap, setAssignedToPersonMap, setCategoryMap, setCustomerName, setCustomerNameMap, setEmailsToNotifyMapData, setIsLoading, setMistakeCodeMap, setNotificationStatusMap, setOpenCreateModal, setPriorityMap, setReasonForNoQuoteMap, setTicketModifyStatus, setTicketTitleMap, setUserEmailMap, setWorkTypeMap } from "./actions/ticketActions";
import { fetchCurrentUser, setTicketStatusMap, userProtected } from "./actions/ticketActions";

function App() {
    const dispatch = useDispatch();
    const hasRun = useRef(false);
    useEffect(() => {
        dispatch(userProtected());
        dispatch(fetchCurrentUser());
    }, []);

    const { mistakeCodes, reasonForNoQuote, contactPoints, ulListings, ticketStatus, categories, priorities, workTypes, groupEmployeData, involveTickets, ticketStatusMap, priorityMapId, workTypeMap, categoryTypeMap, assignedToPersonMap, assignedToGroupMap, reasonForNoQuoteMap, mistakeCodeMap, isProtected, userEmailmap, notificationList, customerNameList, emailsToNotifyMap, createData } = useSelector(state => state.tickets);
    useEffect(() => {
        dispatch(setIsLoading(true));
        if (!hasRun.current && isProtected?.message === "Welcome!") {
            const fetchData = async () => {
                try {
                    await Promise.all([
                        dispatch(fetchMistakeCode()),
                        dispatch(fetchReasonForNoQuote()),
                        dispatch(fetchContactPoints()),
                        dispatch(fetchUlListings()),
                        dispatch(fetchTicketStatus()),
                        dispatch(fetchCategories()),
                        dispatch(fetchPriorities()),
                        dispatch(fetchWorkTypes()),
                        dispatch(fetchGroupEmployeData()),
                        dispatch(fetchCustomerNameData()),
                        dispatch(fetchEmailsToNotifyMap())
                    ]);
                    hasRun.current = true;
                } catch (error) {
                    dispatch(setIsLoading(false));
                    console.error("Error fetching dropdown data: ", error);
                } finally {
                    dispatch(setIsLoading(false));
                }
            };
            fetchData();
        }
    }, [isProtected]);
    const mapDataToId = (title, dataList) => {
        const temp = {};
        if (title === "assignedPerson" || title === "assignedGroup") {
            for (let i = 0; i < dataList?.length; i++) {
                if ((title === "assignedGroup" && dataList[i]?.type === "group") || title === "assignedPerson" && dataList[i]?.type === "employee") {
                    temp[dataList[i]?.id] = dataList[i]?.value;
                }
            }
        } else if (title === "ticketTitleMap") {
            for (let i = 0; i < dataList?.length; i++) {
                temp[dataList[i]?.TicketID] = dataList[i]?.Title;
            }
        } else if (title === "notificationList") {
            for (let i = 0; i < dataList?.length; i++) {
                if (!temp.hasOwnProperty(dataList[i]?.TicketID)) {
                    temp[dataList[i]?.TicketID] = dataList[i]?.Seen;
                }
            }
        } else if (title === "emailsToNotifyMap") {
            for (let i = 0; i < dataList?.length; i++) {
                if (!temp.hasOwnProperty(dataList[i]?.TicketID)) {
                    temp[dataList[i]?.TicketID] = [dataList[i]?.EmployeeID ? assignedToPersonMap[dataList[i]?.EmployeeID] : assignedToGroupMap[dataList[i]?.GroupID]];
                } else {
                    temp[dataList[i]?.TicketID].push(dataList[i]?.EmployeeID ? assignedToPersonMap[dataList[i]?.EmployeeID] : assignedToGroupMap[dataList[i]?.GroupID]);
                }
            }
        }
        else {
            for (let i = 0; i < dataList?.length; i++) {
                if (title === "userEmailmap") {
                    temp[dataList[i]?.id] = dataList[i]?.email;
                } else {
                    temp[dataList[i]?.id] = dataList[i]?.value;
                }
            }
        }
        switch (title) {
            case "ticketStatus":
                dispatch(setTicketStatusMap(temp));
                break;
            case "priorities":
                dispatch(setPriorityMap(temp));
                break;
            case "workTypes":
                dispatch(setWorkTypeMap(temp));
                break;
            case "categories":
                dispatch(setCategoryMap(temp));
                break;
            case "assignedPerson":
                dispatch(setAssignedToPersonMap(temp));
                break;
            case "assignedGroup":
                dispatch(setAssignedToGroupMap(temp));
                break;
            case "reasonForNoQuote":
                dispatch(setReasonForNoQuoteMap(temp));
                break;
            case "mistakeCode":
                dispatch(setMistakeCodeMap(temp));
                break;
            case "customerNameList":
                dispatch(setCustomerNameMap(temp));
                break;
            case "userEmailmap":
                dispatch(setUserEmailMap(temp));
                break;
            case "ticketTitleMap":
                dispatch(setTicketTitleMap(temp));
                break;
            case "notificationList":
                dispatch(setNotificationStatusMap(temp));
                break;
            case "emailsToNotifyMap":
                dispatch(setEmailsToNotifyMapData(temp));
                break;
            default:
                break;
        }
    }
    useEffect(() => {
        localStorage.setItem("mistakeCode", JSON.stringify(mistakeCodes));
        localStorage.setItem("reasonForNoQuote", JSON.stringify(reasonForNoQuote));
        localStorage.setItem("contactPoints", JSON.stringify(contactPoints));
        localStorage.setItem("ulListings", JSON.stringify(ulListings));
        localStorage.setItem("ticketStatus", JSON.stringify(ticketStatus));
        localStorage.setItem("categories", JSON.stringify(categories));
        localStorage.setItem("priorities", JSON.stringify(priorities));
        localStorage.setItem("workTypes", JSON.stringify(workTypes));
        localStorage.setItem("groupEmployeData", JSON.stringify(groupEmployeData));
        localStorage.setItem("customerNameList", JSON.stringify(customerNameList));
        mapDataToId("ticketStatus", ticketStatus);
        mapDataToId("priorities", priorities);
        mapDataToId("workTypes", workTypes);
        mapDataToId("categories", categories);
        mapDataToId("assignedPerson", groupEmployeData);
        mapDataToId("assignedGroup", groupEmployeData);
        mapDataToId("reasonForNoQuote", reasonForNoQuote);
        mapDataToId("mistakeCode", mistakeCodes);
        mapDataToId("customerNameList", customerNameList);
        mapDataToId("userEmailmap", groupEmployeData);
        mapDataToId("ticketTitleMap", involveTickets);
        mapDataToId("notificationList", notificationList);
        mapDataToId("emailsToNotifyMap", emailsToNotifyMap);
    }, [mistakeCodes, reasonForNoQuote, contactPoints, ulListings, ticketStatus, categories, priorities, workTypes, groupEmployeData, customerNameList, involveTickets, notificationList, emailsToNotifyMap]);

    useEffect(() => {
        let temp = {};
        for (let i = 0; i < involveTickets?.length; i++) {
            const ticketID = involveTickets[i]?.TicketStatusID;
            const status = ticketStatusMap[ticketID];
            const priorityId = involveTickets[i]?.PriorityID;
            const priorityStatus = priorityMapId[priorityId];
            let modifiedTicket = {
                ...involveTickets[i],
                WorkTypeID: workTypeMap[involveTickets[i]?.WorkTypeID],
                PriorityID: priorityMapId[involveTickets[i]?.PriorityID],
                CategoryID: categoryTypeMap[involveTickets[i]?.CategoryID],
                AssignedToPersonID: assignedToPersonMap[involveTickets[i]?.AssignedToPersonID],
                AssignedToEmailID: userEmailmap[involveTickets[i]?.AssignedToPersonID],
                AssignedToGroupID: assignedToGroupMap[involveTickets[i]?.AssignedToGroupID],
                ReasonForNoQuoteID: reasonForNoQuoteMap[involveTickets[i]?.ReasonForNoQuoteID],
                MistakeCodeID: mistakeCodeMap[involveTickets[i]?.MistakeCodeID],
                TicketStatusID: ticketStatusMap[involveTickets[i]?.TicketStatusID],
                is_sample_available: involveTickets[i]?.is_sample_available ? "Yes" : "No",
                can_get_sample: involveTickets[i]?.can_get_sample == true ? "Yes" : "No",
                need_to_see_process: involveTickets[i]?.need_to_see_process == true ? "Yes" : "No",
                automated_handling_process: involveTickets[i]?.automated_handling_process == true ? "Yes" : "No",
                sun_lite_resistant_plastic: involveTickets[i]?.sun_lite_resistant_plastic == true ? "Yes" : "No",
                gamma_ray_sterilize: involveTickets[i]?.gamma_ray_sterilize == true ? "Yes" : "No",
                ul_listing_required: involveTickets[i]?.ul_listing_required?.join(", ")
            };
            if (status === "In Progress") {
                if (!temp[status]) {
                    temp[status] = [];
                }
                temp[status].push(modifiedTicket);
            }
            if (priorityStatus === "Normal" || priorityStatus === "Urgent") { // Check if the status exists
                if (!temp["priorityStatus"]) { // Initialize array if it doesn't exist
                    temp["priorityStatus"] = [];
                }
                temp["priorityStatus"].push(modifiedTicket);
                if (!temp["allData"]) {
                    temp["allData"] = [];
                }
                temp["allData"].push(modifiedTicket);
            }
            if (!temp["allData"]) {
                temp["allData"] = [];
            }
            const isTicketUnique = !temp["allData"].some(item => item.TicketID == modifiedTicket.TicketID);

            if (modifiedTicket.TicketID && !temp["allData"].some(item => item.TicketID === modifiedTicket.TicketID)) {
                temp["allData"].push(modifiedTicket);
            }
        }
        dispatch(setTicketModifyStatus(temp));
    }, [ticketStatusMap, involveTickets, createData]);

    return (
        <Router>
            {isProtected?.message === "Welcome!" && <Navbar1 />}
            <Routes>
                {/* <Route path="/login" element= {<Login />}/> */}
                <Route path="/create-ticket-temp" element={<TicketForm />} />
                <Route path="/dashboard" element={
                    <div>
                        <div className="outer_div">
                            {`Bearer ${localStorage.getItem("accessToken")}` ? <Navbar1 /> : ""}
                            <Dashboard />
                        </div>
                    </div>}
                />
            </Routes>
            <Routes>
                <Route path="/" element={<DashboardTable />} />
                <Route path="/manage-category" element={<DashboardTable />} />
                <Route path="/preview" element={<PDFPreview />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path={`/edit-ticket`} element={<DashboardTable />} />
                <Route path={`/employee-skill`} element={<DashboardTable />} />
                <Route path={`/manage-groups`} element={<DashboardTable />} />
                <Route path="/create-ticket" element={<CreateTicket />} />
                {/* <Route path="/edit-ticket/?ticketId=${}" element={<EditTicket />} /> */}
                <Route path="/check" element={<SimpleGrid />} />
            </Routes>
            <Toaster />
        </Router>
    );
}

export default App;
