import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import PersonIcon from '@mui/icons-material/Person';
import Select, { components } from "react-select";
import DropdownControllers from "../dashboard/DropdownControllers";
import { createNewCategory, setAddNewCategory } from "../../actions/ticketActions";
import { useEffect } from "react";

const AddCategoryForm = ({ handleCategoryClose }) => {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();
    const dispatch = useDispatch();
    const { groupEmployeData, assignedToPersonMap, addNewCategory } = useSelector(state => state.tickets);
    const onSubmit = (data) => {
        dispatch(createNewCategory({
            CategoryName: data?.category,
            autoassign: data?.autoAssign,
            plant_manager: data?.plant_manager?.id
        }));
    }
    useEffect(() => {
        if (addNewCategory?.status === "success") {
            handleCategoryClose();
            dispatch(setAddNewCategory({}));
        }
    }, [addNewCategory]);
    const CustomOption = (props) => {
        return (
            <components.Option {...props}>
                {/* Render an icon and the option label */}
                <div style={{ display: "flex", alignItems: "center" }}>
                    {props.data.icon && <span style={{ marginRight: "8px" }}>{props.data.icon}</span>}
                    {props.data.label}
                </div>
            </components.Option>
        );
    };
    return (
        <>
            <div className="outer_form">
                <div className="inner_form_category">
                    <p className="form_header_label">Add Category</p>
                    <form onSubmit={handleSubmit(onSubmit)} className="form_main">
                        <div className={errors.category ? "form_error_div" : "form_div"}>
                            <label>Category Name <span>*</span></label><br></br>
                            <input
                                type="text"
                                {...register("category", { required: true })}
                                placeholder="Enter Category Name"
                                className="input_field_category"
                            />
                            <br></br>
                            {errors.title && <span className="required_text">Category is required</span>}
                        </div>
                        <div className="form_div">
                            <div className={errors.assigned_to ? "form_error_div" : "form_edit_div"}>
                                <label>Plant Manager <span>*</span></label><br />
                                <Controller
                                    name="plant_manager"
                                    rules={{ required: "Plant Manager is required" }}
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={groupEmployeData.filter(item => item.type === "employee").map(item => ({
                                                id: item?.id,
                                                value: assignedToPersonMap[item?.id],
                                                label: assignedToPersonMap[item?.id],
                                                type: item?.type,
                                                icon: <PersonIcon />,
                                                email: item?.email,
                                            }))}
                                            styles={DropdownControllers.controlStylesDropdown}
                                            placeholder="Select Assignee"
                                            components={{ Option: CustomOption }}
                                        />
                                    )}
                                />
                                <br></br>
                                {errors.assigned_to && <span className="required_text">Plant Manager is required</span>}
                            </div>
                        </div>
                        <div className="checkbox_container">
                            <label htmlFor="autoAssign" className="checkbox_label">Auto assign</label>
                            <input
                                type="checkbox"
                                className="check_box_field"
                                {...register("autoAssign")}
                            />
                        </div>
                        <div className="btn_div">
                            <button
                                id="cancel_btn"
                                type="button"
                                onClick={handleCategoryClose}
                            >
                                Cancel
                            </button>
                            <button
                                id="edit_ticket"
                                type="submit"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default AddCategoryForm;