const sortBasedOnPriority = (arr) => {
  return arr.sort((a, b) => {
    const priorityOrder = { Urgent: 1, Normal: 2 }; 
    const priorityA = priorityOrder[a.PriorityID] || 3; 
    const priorityB = priorityOrder[b.PriorityID] || 3;

    if (priorityA !== priorityB) {
      return priorityA - priorityB;
    }

    const dateA = new Date(a.CreatedTimestamp);
    const dateB = new Date(b.CreatedTimestamp);

    return dateA - dateB;
  });
};

export default sortBasedOnPriority;
