import React from 'react';
import "./index.css";

const ShowCellRenderer = (params) => {
    if (!params.value) {
        return;
    }
    return (
            <div className={"top_cell"}>
                <span className="cell_value">{params.value}</span>
            </div>
    );
};
export default ShowCellRenderer;
