
import Dashboard from "./Dashboard";
import Navbar1 from "../navbar/Navbar1";

const DashboardTable = () => {
    return (
        <>
        <div className="outer_div">
        {/* <Navbar1/> */}
        <Dashboard/>
        </div>
        </>
    );
};

export default DashboardTable;