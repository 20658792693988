import * as React from 'react';
import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenRevisionModal } from '../../actions/ticketActions';
import RevisionTicket from './RevisionTicket';

const RevisionTicketModal = () => {
    const dispatch = useDispatch();
    const { openRevisionModal } = useSelector(state => state.tickets);
    const handleClose = () => dispatch(setOpenRevisionModal(false));

    return (
        <>
            <Modal
                open={openRevisionModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflowY: "auto",
                    height:"auto",
                    marginTop:"20px",
                    marginBottom:"20px",
                    overflow: "hidden",
                    // overflow: 'auto', 
                }}
            >
                {/* <Box
                    sx={{
                        width: '80%',        // Adjust width as needed
                        // maxHeight: '90vh',    
                        // overflowY: 'auto',    
                        // bgcolor: "#ef6e35",
                        p: 4,
                        boxShadow: 24,
                    }}
                > */}
                    <RevisionTicket />
                {/* </Box> */}
            </Modal>
        </>
    );
}
export default RevisionTicketModal;
