import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import PersonIcon from "@mui/icons-material/Person";
import Select, { components } from "react-select";
import DropdownControllers from "../dashboard/DropdownControllers";
import {
    addNewEmployeeSkills,
    deleteExistingEmployeeSkills,
    setAddEmployeeSkills,
    updateEmployeeSkillsData,
} from "../../actions/ticketActions";
import { useEffect, useState } from "react";

const AddSkillForm = ({ handleSkillClose, selectedJobType, selectedCategory, selectedEmployees, selectedCategoryMap, isEdit, workTimes, employeeCategoryMap }) => {
    const dispatch = useDispatch();
    const [defaultJobType, setDefaultJobType] = useState([]);
    const [defaultCategory, setDefaultCategory] = useState([]);
    const [defaultEmployee, setDefaultEmployee] = useState([]);
    const [defaultWorkTime, setDefaultWorkTime] = useState("");
    const [errors, setErrors] = useState({});

    const {
        handleSubmit,
        control,
        register,
        formState: { errors: formErrors },
    } = useForm();

    const jobTypeList = [
        { id: 1, value: "CAD Drawing", label: "CAD Drawing" },
        { id: 2, value: "Order Part", label: "Order Part" },
        { id: 3, value: "Word Spec", label: "Word Spec" },
        { id: 4, value: "Quote Part", label: "Quote Part" },
    ];

    const { groupEmployeData, assignedToPersonMap, addEmployeeSkills, categories } = useSelector((state) => state.tickets);

    const findAddedAndRemovedItems = (oldSelection, newSelection) => {
        const oldIds = oldSelection.map((item) => item.id);
        const newIds = newSelection.map((item) => item.id);

        const addedItems = newSelection.filter((item) => !oldIds.includes(item.id));
        const removedItems = oldSelection.filter((item) => !newIds.includes(item.id));

        return { addedItems, removedItems };
    };

    const onSubmit = (data) => {
        const newErrors = {};
        if (!defaultEmployee) newErrors.employee = "Employee is required.";
        if (!defaultCategory) newErrors.category = "Category is required.";
        if (defaultJobType?.length === 0) newErrors.jobType = "Job Type is required.";
        if (!defaultWorkTime) newErrors.workTime = "Work Time is required.";
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            if (isEdit) {
                const { addedItems, removedItems } = findAddedAndRemovedItems(selectedJobType, defaultJobType);
                if (addedItems) {
                    for (let i = 0; i < addedItems?.length; i++) {
                        dispatch(
                            addNewEmployeeSkills({
                                CategoryID: defaultCategory?.id,
                                job_type: addedItems[i]?.value,
                                EmployeeID: defaultEmployee?.id,
                                work_time: defaultWorkTime,
                            })
                        );
                    }
                }
                if (removedItems) {
                    for (let i = 0; i < removedItems?.length; i++) {
                        dispatch(deleteExistingEmployeeSkills(removedItems[i]?.skillId));
                    }
                }
                if (defaultWorkTime && (workTimes !== defaultWorkTime) && addedItems?.length === 0 && removedItems?.length === 0) {
                    if (selectedJobType?.length > 0) {
                        dispatch(updateEmployeeSkillsData([{
                            id: selectedJobType[0]?.skillId,
                            work_time: defaultWorkTime,
                        }]));
                    }
                }
            } else {
                for (let i = 0; i < defaultJobType?.length; i++) {
                    dispatch(
                        addNewEmployeeSkills({
                            CategoryID: defaultCategory?.id,
                            job_type: defaultJobType[i]?.value,
                            EmployeeID: defaultEmployee?.id,
                            work_time: defaultWorkTime,
                        })
                    );
                }
            }
        }
    };

    useEffect(() => {
        if (addEmployeeSkills?.status === "success") {
            handleSkillClose();
            dispatch(setAddEmployeeSkills({}));
        }
    }, [addEmployeeSkills]);

    useEffect(() => {
        setDefaultJobType(selectedJobType || []);
        setDefaultCategory(selectedCategory || null);
        setDefaultEmployee(selectedEmployees || null);
        setDefaultWorkTime(workTimes || "");
    }, [selectedJobType, selectedCategory, selectedEmployees]);

    const handleValueChange = (field, value) => {
        if (field === "employee") {
            setDefaultEmployee(value);
            setErrors((prev) => ({ ...prev, employee: "" }));
            setDefaultCategory(null);
            setDefaultJobType(null);
        } else if (field === "category") {
            setDefaultCategory(value);
            if (employeeCategoryMap && employeeCategoryMap[defaultEmployee?.id] && employeeCategoryMap[defaultEmployee?.id]?.[value?.id]) {
                setDefaultJobType(employeeCategoryMap[defaultEmployee?.id][value?.id]);
            } else {
                setDefaultJobType(null);
            }
            setErrors((prev) => ({ ...prev, category: "" }));
        } else if (field === "jobType") {
            setDefaultJobType(value);
            setErrors((prev) => ({ ...prev, jobType: "" }));
        } else if (field === "workTime") {
            setDefaultWorkTime(value);
            setErrors((prev) => ({ ...prev, workTime: "" }));
        }
    };
    const CustomOption = (props) => (
        <components.Option {...props}>
            <div style={{ display: "flex", alignItems: "center" }}>
                {props.data.icon && <span style={{ marginRight: "8px" }}>{props.data.icon}</span>}
                {props.data.label}
            </div>
        </components.Option>
    );

    return (
        <div className="outer_form">
            <div className="inner_form_category">
                <p className="form_header_label">{isEdit ? "Edit" : "Add"} Skill</p>
                <form onSubmit={handleSubmit(onSubmit)} className="form_main">
                    {/* Employee */}
                    <div className={errors.employee ? "form_error_div" : "form_edit_div"}>
                        <label>
                            Employee <span>*</span>
                        </label>
                        <Controller
                            name="employee"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={groupEmployeData.filter(item => item.type === "employee").map((item) => ({
                                        id: item?.id,
                                        value: assignedToPersonMap[item?.id],
                                        label: assignedToPersonMap[item?.id],
                                        icon: <PersonIcon />,
                                    }))}
                                    value={defaultEmployee}
                                    styles={DropdownControllers.controlStylesDropdown}
                                    placeholder="Select Employee"
                                    components={{ Option: CustomOption }}
                                    onChange={(value) => handleValueChange("employee", value)}
                                    isDisabled={isEdit}
                                />
                            )}
                        />
                        {errors.employee && <span className="required_text">{errors.employee}</span>}
                    </div>
                    <br></br>
                    {/* Category */}
                    <div className={errors.category ? "form_error_div" : "form_edit_div"}>
                        <label>
                            Category <span>*</span>
                        </label>
                        <Controller
                            name="category"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={categories}
                                    value={defaultCategory}
                                    styles={DropdownControllers.controlStylesDropdown}
                                    placeholder="Select Category"
                                    components={{ Option: CustomOption }}
                                    onChange={(value) => handleValueChange("category", value)}
                                    isDisabled={isEdit}
                                />
                            )}
                        />
                        {errors.category && <span className="required_text">{errors.category}</span>}
                    </div>
                    <br></br>
                    {/* Job Type */}
                    <div className={errors.jobType ? "form_error_div" : "form_edit_div"}>
                        <label>
                            Job Type <span>*</span>
                        </label>
                        <Controller
                            name="jobType"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={jobTypeList}
                                    value={defaultJobType}
                                    styles={DropdownControllers.controlStylesDropdown}
                                    placeholder="Select Job Type"
                                    components={{ Option: CustomOption }}
                                    onChange={(value) => handleValueChange("jobType", value)}
                                    isMulti={true}
                                />
                            )}
                        />
                        {errors.jobType && <span className="required_text">{errors.jobType}</span>}
                    </div>
                    <br></br>
                    {/* Work Time */}
                    <div className={errors.workTime ? "form_error_div" : "form_edit_div"}>
                        <label>
                            Work Time <span>*</span>
                        </label>
                        <br></br>
                        <input
                            type="text"
                            value={defaultWorkTime}
                            onChange={(e) => handleValueChange("workTime", e.target.value)}
                            placeholder="Enter Work Time"
                            className="input_field_category"
                        />
                        <br></br>
                        {errors.workTime && <span className="required_text">{errors.workTime}</span>}
                    </div>
                    {/* Buttons */}
                    <div className="btn_div">
                        <button id="cancel_btn" type="button" onClick={handleSkillClose}>
                            Cancel
                        </button>
                        <button id="edit_ticket" type="submit">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddSkillForm;
