import * as React from "react";
import Modal from "@mui/material/Modal";
import AddCategoryForm from "../TicketOperations/AddCategoryForm";
import AddGroupForm from "../TicketOperations/AddGroupForm";

export default function AddGroupModal({isCategoryModal, handleCategoryClose}) {
    return (
        <Modal
            open={isCategoryModal}
            onClose={handleCategoryClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflowY: "auto",
                height: "auto",
                marginTop: "20px",
                marginBottom: "20px",
                overflow: "hidden",
                // paddingTop:"40px"
                // width:"10px"
                // overflow: "auto", 
            }}
        >
                <AddGroupForm handleCategoryClose={handleCategoryClose}/>
        </Modal>
    );
}
