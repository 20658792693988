import CustomHeader from "../dashboard/CustomHeader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DnsIcon from '@mui/icons-material/Dns';
import CustomDropdownEditor from "../dashboard/CustomDropdownEditor";
import ActionGroupEmployeeRenderer from "../dashboard/ActionGroupEmployeeRenderer";
const isMobile = window.innerWidth <= 768;

const employeeGroupColumns = [
    {
        field: "EmployeeGroupID",
        headerComponent: CustomHeader,
        headerComponentParams: { icon: <InfoOutlinedIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Employee Group ID" },
        filter: "agNumberColumnFilter",
        minWidth: isMobile ? 40 : 250,
        filterParams: {
            defaultToNothingSelected: true,
            // default: "Urgent",  
        },
        cellStyle: (params) => {
            return {
                backgroundColor: "#222222",
                color: "white",
                cursor: "pointer"
            };
        },
        pinned: true,
        // hide: isMobile,
    },
    {
        field: "EmployeeID",
        cellEditor: 'agSelectCellEditor',
        headerComponent: CustomHeader,
        headerComponentParams: { icon: <DnsIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Employee Name" },
        filter: isMobile ? "" : "agSetColumnFilter",
        // minWidth: isMobile ? 40 : 250,
        filterParams: {
            defaultToNothingSelected: true,
        },
        editable: true,
        cellEditorParams: (params) => {
            const dropdownValues = params?.data?.emp_list?.map(item => `${item.label} (${item.id})`) || [];
            
            return {
                values: dropdownValues,
                valueListMaxHeight: 220,
            };
        },
        cellStyle: (params) => {
            return {
                backgroundColor: "#222222",
                color: "white",
                cursor: "pointer",
            };
        },
        cellEditorFramework: CustomDropdownEditor,
        // hide: isMobile,
    },
    {
        field: "GroupID",
        cellEditor: 'agSelectCellEditor',
        headerComponent: CustomHeader,
        headerComponentParams: { icon: <DnsIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Group Name" },
        filter: isMobile ? "" : "agSetColumnFilter",
        // minWidth: isMobile ? 40 : 250,
        filterParams: {
            defaultToNothingSelected: true,
        },
        editable: true,
        cellEditorParams: (params) => {
            const dropdownValues = params?.data?.groupTableData?.map(item => `${item.GroupName} (${item.GroupID})`) || [];
            
            return {
                values: dropdownValues,
                valueListMaxHeight: 220,
            };
        },
        cellStyle: (params) => {
            return {
                backgroundColor: "#222222",
                color: "white",
                cursor: "pointer",
            };
        },
        cellEditorFramework: CustomDropdownEditor,
        hide: isMobile,
    },
    {
        headerName: 'Delete',
        field: 'actions',
        cellRenderer: ActionGroupEmployeeRenderer,
        flex: 1, // Adjust width
        // minWidth: 150,
        resizable: true,
        sortable: false,
        filter: false,
        cellStyle: (params) => {
            return {
                backgroundColor: "#222222",
                color: "white",
            };
        },
        // pinned: true
    },
]
export default employeeGroupColumns;