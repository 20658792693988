import React, { useMemo, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select, { components } from "react-select";
import MultiSelectDropdown from "../TicketOperations/MultiselectDropDown";
import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";
import AttachedFile from "../TicketOperations/AttachedFile";
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import "./index.css";
import RichText from '../TicketOperations/RichText';
import DropdownControllers from "../dashboard/DropdownControllers";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditPayload from "./EditPayload";
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import CreatableSelect from "react-select/creatable";
import { automatedHandlings, cableContactPointList, gammaRays, getAvailableSample, isSampleAvailable, processCableList, pullForceNeeded, seeProcess, sunLightResistancePlastic, UlListing, UlListingMapping } from "../TicketOperations/DropdownOptions";
import {
  editTicketData,
  setOpenEditModal,
  uploadUrlTicketEpic,
  putSignedUrl,
  fetchAttachments,
  getEmailsToNotify,
  setTicketData,
  deleteAttachmentResponse,
  setFileToRemove,
  fetchTicketStatus,
  fetchGroupEmployeData,
  fetchLineageTicket,
  setAttachmentLoading,
  setPutSignedSuccess,
  setIsBackdropLoading,
  fetchEmployeeWorkLoad,
  setParentTicket,
  setOpenRevisionModal
} from "../../actions/ticketActions";
import { useNavigate, useSearchParams } from "react-router-dom";
import { showToastError } from "../common/toast";
import { Box, CircularProgress } from "@mui/material";
import CircularBackdrop from "./CircularBackdrop";
import Comments from "./Comments";
const isMobile = window.innerWidth <= 768;


const initialValues = {
  title: "",
  workType: "",
  priority: "",
  category: "",
  assigned_to: "",
  price: "",
  ticketStatus: "",
  description: "",
  customerName: "",
  assignedPartNumber: [],
  quotePartRequired: 0,
  orderPartRequired: 0,
  wordSpecsRequired: 0,
  cadDrawingsRequired: 0,
  late_reason: "",
  notes: "",
  noquote_reason: "",
  mistake_code: "",
  notes_sales: "",
  footage: "",
  emailsToNotify: [],
  attachments: [],
};

const EditTicketForm = () => {
  const { register, handleSubmit, control, setValue, reset, watch, formState: { errors, dirtyFields } } = useForm();
  const watchedValues = watch();
  const [attachmentsList, setAttachmentsList] = useState([]);
  const [fileData, setFileData] = useState();
  const [progress, setProgress] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [keywordsValue, setKeywordsValue] = useState([]);
  const [validationMsg, setValidationMsg] = useState();
  const pullForceNeed = watch("pullForceNeed");
  const automatedHandling = watch("automatedHandling");
  const quotePartRequired = watch("quotePartRequired");
  const orderPartRequired = watch("orderPartRequired");
  const wordSpecsRequired = watch("wordSpecsRequired");
  const cadDrawingsRequired = watch("cadDrawingsRequired");
  const testingRequired = watch("testingRequired");

  useEffect(() => {
    if (quotePartRequired || orderPartRequired || wordSpecsRequired || cadDrawingsRequired) {
      setValidationMsg("");
    } else {
      setValidationMsg("At least one field is required");
    }
  }, [quotePartRequired, orderPartRequired, wordSpecsRequired, cadDrawingsRequired]);
  // Create an object with only the modified (dirty) fields
  const modifiedData = Object.keys(dirtyFields).reduce((acc, key) => {
    acc[key] = watchedValues[key];
    return acc;
  }, {});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    mistakeCodes,
    reasonForNoQuote,
    contactPoints,
    ulListings,
    ticketStatus,
    categories,
    priorities,
    workTypes,
    groupEmployeData,
    ticketData,
    workTypeMap,
    priorityMapId,
    categoryTypeMap,
    assignedToPersonMap,
    assignedToGroupMap,
    reasonForNoQuoteMap,
    mistakeCodeMap,
    ticketStatusMap,
    customerNameList,
    uploadUrlTicket,
    attachmentResponse,
    openEditModal,
    deleteAttachmentRes,
    emailsToNotifyResponse,
    involveTicketMap,
    fileToRemove,
    customerNameMap,
    ticketTitleMap,
    lineageData,
    attachmentLoading,
    putSignedSuccess,
    isBackdropLoading,
    employeeWorkLoad,
    isLoading,
  } = useSelector(state => state.tickets);
  const categoryValue = watch("category");
  const isSampleValue = watch("isSample");
  const [searchParams] = useSearchParams();
  const ticketId = useMemo(() => searchParams.get("ticketId"), [searchParams]);
  const hasRun = useRef(false);

  useEffect(() => {
    if (attachmentResponse) {
      let temp = [];
      for (let i = 0; i < attachmentResponse?.length; i++) {
        temp.push(attachmentResponse[i]);
      }
      setAttachmentsList(temp);
    }
  }, [attachmentResponse, ticketId]);

  useEffect(() => {
    if (!hasRun.current && ticketId) {
      dispatch(getEmailsToNotify(ticketId));
      dispatch(fetchAttachments(ticketId));
      hasRun.current = true;
    };
  }, [ticketId]);

  useEffect(() => {
    const ticketId = searchParams.get("ticketId");
    if (ticketId) {
      const fetchData = async () => {
        await dispatch(setTicketData(involveTicketMap[ticketId]));
        dispatch(setOpenEditModal(true));
      };
      fetchData();
    }
  }, [involveTicketMap]);

  const onSubmit = () => {
    if (keywordsValue?.length <= 0) return;
    if (validationMsg) return;
    dispatch(setIsBackdropLoading(true));
    const transformedData = EditPayload(modifiedData);
    if (attachmentsList?.length > 0) {
      dispatch(editTicketData({ ...transformedData, TicketID: ticketData?.TicketID, attachments: attachmentsList?.map((item) => item?.FileUrl), AssignedPartNumber: keywordsValue?.map((item) => item?.value)?.join(", ") }));
      if (fileToRemove) {
        dispatch(deleteAttachmentResponse(fileToRemove));
      }
    } else {
      dispatch(editTicketData({ ...transformedData, TicketID: ticketData?.TicketID, AssignedPartNumber: keywordsValue?.map((item) => item?.value)?.join(", ") }));
    }
  };
  useEffect(() => {
    if (deleteAttachmentRes?.message == "Attachment deleted") {
      dispatch(setFileToRemove(""));
      dispatch(fetchAttachments(ticketId));
    }
  }, [deleteAttachmentRes]);

  useEffect(() => {
    localStorage.setItem("mistakeCode", JSON.stringify(mistakeCodes));
    localStorage.setItem("reasonForNoQuote", JSON.stringify(reasonForNoQuote));
    localStorage.setItem("contactPoints", JSON.stringify(contactPoints));
    localStorage.setItem("ulListings", JSON.stringify(ulListings));
    localStorage.setItem("ticketStatus", JSON.stringify(ticketStatus));
    localStorage.setItem("categories", JSON.stringify(categories));
    localStorage.setItem("priorities", JSON.stringify(priorities));
    localStorage.setItem("workTypes", JSON.stringify(workTypes));
    localStorage.setItem("groupEmployeData", JSON.stringify(groupEmployeData));
    localStorage.setItem("customerNameList", JSON.stringify(customerNameList));
  }, [mistakeCodes, reasonForNoQuote, contactPoints, ulListings, ticketStatus, categories, priorities, workTypes, groupEmployeData, customerNameList]);

  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        {/* Render an icon and the option label */}
        <div style={{ display: "flex", alignItems: "center" }}>
          {props.data.icon && <span style={{ marginRight: "8px" }}>{props.data.icon}</span>}
          {props.data.label}
        </div>
      </components.Option>
    );
  };

  useEffect(() => {
    dispatch(fetchTicketStatus());
    dispatch(fetchGroupEmployeData());
    dispatch(fetchLineageTicket(searchParams.get("ticketId")));
  }, []);

  useEffect(() => {
    if (ticketData?.CustomerID && customerNameMap) {
      setValue("customerName", {
        id: ticketData.CustomerID,
        label: customerNameMap[ticketData.CustomerID],
        value: customerNameMap[ticketData.CustomerID],
      });
    }
  }, [ticketData, customerNameMap, setValue]);

  useEffect(() => {
    if (ticketData) {
      reset({
        title: ticketData.Title || "",
        workType: ticketData?.WorkTypeID && { id: ticketData?.WorkTypeID, value: workTypeMap[ticketData?.WorkTypeID], label: workTypeMap[ticketData?.WorkTypeID] },
        priority: ticketData?.PriorityID && { id: ticketData?.PriorityID, value: priorityMapId[ticketData?.PriorityID], label: priorityMapId[ticketData?.PriorityID] },
        category: ticketData?.CategoryID && { id: ticketData?.CategoryID, value: categoryTypeMap[ticketData?.CategoryID], label: categoryTypeMap[ticketData?.CategoryID] },
        assigned_to: ticketData?.AssignedToGroupID ?
          { id: ticketData?.AssignedToGroupID, value: assignedToGroupMap[ticketData?.AssignedToGroupID], label: assignedToGroupMap[ticketData?.AssignedToGroupID] }
          : { id: ticketData?.AssignedToPersonID, value: assignedToPersonMap[ticketData?.AssignedToPersonID], label: assignedToPersonMap[ticketData?.AssignedToPersonID] },
        ticketStatus: ticketData?.TicketStatusID && { id: ticketData?.TicketStatusID, value: ticketStatusMap[ticketData?.TicketStatusID], label: ticketStatusMap[ticketData?.TicketStatusID] },
        noquote_reason: ticketData?.ReasonForNoQuoteID && { id: ticketData?.ReasonForNoQuoteID, value: reasonForNoQuoteMap[ticketData?.ReasonForNoQuoteID], label: reasonForNoQuoteMap[ticketData?.ReasonForNoQuoteID] },
        mistake_code: ticketData?.MistakeCodeID && { id: ticketData?.MistakeCodeID, value: mistakeCodeMap[ticketData?.MistakeCodeID], label: mistakeCodeMap[ticketData?.MistakeCodeID] },
        description: ticketData?.Description,
        customerName: ticketData?.CustomerID && customerNameMap && { id: ticketData?.CustomerID, label: customerNameMap[ticketData?.CustomerID], value: customerNameMap[ticketData?.CustomerID] },
        cableContactPoints: ticketData?.contact_points_with_elements?.split(", ")?.map((item) => { return { label: item, value: item } }),
        assignedPartNumber: ticketData?.AssignedPartNumber?.split(", ")?.map((item) => {
          return {
            label: item,
            value: item,
          }
        }),
        quotePartRequired: ticketData?.QuotePartRequired,
        wordSpecsRequired: ticketData?.WordSpecsRequired,
        orderPartRequired: ticketData?.OrderPartRequired,
        cadDrawingsRequired: ticketData?.CADDrawingsRequired,
        late_reason: ticketData?.ReasonForLate,
        notes: ticketData?.Notes,
        notes_sales: ticketData?.Notes,
        footage: ticketData?.Footage && Number(ticketData?.Footage)?.toLocaleString("en-US"),
        isSample: ticketData?.is_sample_available == true ? { id: "Yes", value: "Yes", label: "Yes" } : { id: "No", value: "No", label: "No" },
        getSample: ticketData?.can_get_sample == true ? { id: "Yes", value: "Yes", label: "Yes" } : { id: "No", value: "No", label: "No" },
        isProcess: ticketData?.need_to_see_process == true ? { id: "Yes", value: "Yes", label: "Yes" } : { id: "No", value: "No", label: "No" },
        processApplication: ticketData?.process_cable_used_in?.split(", ")?.map(value => ({
          label: value,
          value: value
        })),
        price: ticketData?.Price,
        automatedHandling: ticketData?.automated_handling_process == true ? { id: "Yes", value: "Yes", label: "Yes" } : { id: "No", value: "No", label: "No" },
        pullForceNeed: ticketData?.specific_pull_force_required == true ? { id: "Yes", value: "Yes", label: "Yes" } : { id: "No", value: "No", label: "No" },
        testingRequired: ticketData?.SpecialTesting == true ? { id: "Yes", value: "Yes", label: "Yes" } : { id: "No", value: "No", label: "No" },
        testingProcedure: ticketData?.SpecialTestingText,
        newColumnAutomated: ticketData?.automated_handling_details,
        newColumnPullForce: ticketData?.pull_force_value,
        sunLightRes: ticketData?.sun_lite_resistant_plastic == true ? { id: "Yes", value: "Yes", label: "Yes" } : { id: "No", value: "No", label: "No" },
        gammaRaysSterilize: ticketData?.gamma_ray_sterilize == true ? { id: "Yes", value: "Yes", label: "Yes" } : { id: "No", value: "No", label: "No" },
        ulListingRequired: ticketData?.ul_listing_required?.map(value => ({
          id: value,
          label: UlListingMapping[value],
          value: UlListingMapping[value]
        })),
        emailsToNotify: emailsToNotifyResponse?.map(value => ({
          id: value?.GroupID ? value?.GroupID : value?.EmployeeID,
          label: value?.GroupID ? assignedToGroupMap[value?.GroupID] : assignedToPersonMap[value?.EmployeeID],
          value: value?.GroupID ? assignedToGroupMap[value?.GroupID] : assignedToPersonMap[value?.EmployeeID],
          icon: value?.GroupID ? <GroupIcon /> : <PersonIcon />
        })) || []
      });
    }
  }, [ticketData, reset, ticketStatus, workTypeMap, ticketData?.CustomerID, assignedToGroupMap, customerNameList, UlListingMapping, assignedToPersonMap, priorityMapId, categoryTypeMap, emailsToNotifyResponse, customerNameMap]);

  useEffect(() => {
    if (ticketData?.CategoryID) {
      dispatch(fetchEmployeeWorkLoad(ticketData?.CategoryID));
    }
  }, [ticketData])
  useEffect(() => {
    if (uploadUrlTicket?.file_name) {
      const file = fileData;
      if (file) {
        dispatch(putSignedUrl({
          url: uploadUrlTicket?.url,
          data: file
        }, dispatch))
      }
    }
  }, [uploadUrlTicket?.file_name]);

  useEffect(() => {
    if (putSignedSuccess?.code === "00000") {
      const temp = [...attachmentsList];
      if (uploadUrlTicket?.file_name)
        temp.push({ "FileUrl": uploadUrlTicket?.file_name });
      setAttachmentsList(temp);
      dispatch(setPutSignedSuccess({}));
    }
  }, [putSignedSuccess])

  useEffect(() => {
    if (openEditModal) {
      setAttachmentsList([]);
    }
  }, [openEditModal]);

  const handleUploadImage = (e) => {
    dispatch(setAttachmentLoading(true));
    const file = e?.currentTarget?.files?.[0];
    const imageType = file?.type.split("/")[1];
    setProgress(0); // Reset progress
    const reader = new FileReader();
    reader.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentLoaded = Math.round((event.loaded / event.total) * 100);
        setProgress(percentLoaded);
      }
    };
    reader.onloadend = () => {
      setProgress(100); // Set to 100% when done
    };
    reader.readAsDataURL(file);
    const payload = {
      "file_extension": imageType,
      "TicketID": ticketData?.TicketID
    }
    dispatch(uploadUrlTicketEpic(payload));
    setFileData(file);
  };
  const createOption = (label) => ({
    label,
    value: label,
  });
  const handleKeyDown = (event) => {
    if (!inputValue) {
      return;
    }
    switch (event.key) {
      case "Enter":
      case "Tab":
        if (keywordsValue?.length < 10 && inputValue?.length <= 50) {
          setKeywordsValue((prev) => [...prev, createOption(inputValue)]);
          setInputValue("");
          event.preventDefault();
        } else if (inputValue?.length > 50) {
          showToastError("Please Enter maximum 50 characters");
        } else {
          showToastError("You can Enter maximum 10 keywords");
        }
        break
      default:
        break
    }
  };

  useEffect(() => {
    setKeywordsValue(
      ticketData?.AssignedPartNumber?.split(", ")?.map((item) => {
        return {
          label: item,
          value: item,
        }
      }));
  }, [ticketData?.AssignedPartNumber]);

  const handleFootageChange = (e) => {
    const rawValue = e.target.value.replace(/,/g, "");
    if (!isNaN(rawValue) && rawValue !== "") {
      const formattedValue = Number(rawValue).toLocaleString("en-US");
      setValue("footage", rawValue, { shouldDirty: true });
      e.target.value = formattedValue;
    } else if (rawValue === "") {
      setValue("footage", "", { shouldDirty: true });
    }
  }
  const handleCategoryChange = (e) => {
    dispatch(fetchEmployeeWorkLoad(e?.id));
    setValue("assigned_to", []);
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} name="edit_ticket_form" id="edit_ticket_form">
      <div className="edit_form_header">
        <div className="revision_div">
          <h1>Edit Ticket</h1>
          <button className="revision_btn" onClick={() => { dispatch(setParentTicket(ticketId)); dispatch(setOpenRevisionModal(true)); navigate("/"); dispatch(setOpenEditModal(false)) }}>Create Revision</button>
        </div>
        {!isLoading &&
          <div className="ticket_createdby_text">
            <p className="ticket_text">Ticket Id: <span className="user_text">{ticketData?.TicketID}</span></p>
            <p className="ticket_text_select">Created by: <span className="user_text">{ticketData?.CreatedBy && assignedToPersonMap[ticketData?.CreatedBy]}</span></p>
            <p className="ticket_text_select">Assigned to: <span className="user_text">{ticketData?.AssignedToGroupID ? assignedToGroupMap[ticketData?.AssignedToGroupID] : assignedToPersonMap[ticketData?.AssignedToPersonID]}</span></p>
            <div className="ticket_text_select" id="select_ticket_status">
              <p>Status: </p>
              <Controller
                name="ticketStatus"
                control={control}
                rules={{ required: "This is required" }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={ticketStatus}
                    styles={DropdownControllers.controlStyles}
                    placeholder="Ticket Status"
                  />
                )}
              />
            </div>
          </div>}
      </div>
      <div className="edit_form">

        {isLoading ? <Box sx={{ display: "flex", height: "80vh", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress />
        </Box> :
          <div className="edit_form_container">
            <hr className="field_seperator" />
            <div className="form_col_edit">
              <div className={errors.title ? "form_error_edit_div" : "form_edit_div"}>
                <label>Title<span>*</span></label><br />
                <input
                  type="text"
                  {...register("title", { required: true })}
                  className="edit_title"
                  placeholder="Title"
                  defaultValue={ticketData?.Title}
                />
                <br></br>
                {errors.title && <span className="required_text">Title is required</span>}
              </div>

              <div className={errors.workType ? "form_error_edit_div" : "form_edit_div"}>
                <label>Work Type <span>*</span></label><br />
                <Controller
                  name="workType"
                  control={control}
                  rules={{ required: "Work Type is required" }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={workTypes}
                      defaultValue={{ id: ticketData?.WorkTypeID, value: workTypeMap[ticketData?.WorkTypeID], label: workTypeMap[ticketData?.WorkTypeID] }}
                      styles={DropdownControllers.controlStyles}
                      placeholder="Select Work Type"
                      className="edit_work_type"
                    />
                  )}
                />
                <br></br>
                {errors.workType && <span className="required_text">Work Type is required</span>}
              </div>
            </div>
            <div className="form_col_edit">
              <div className={errors.priority ? "form_error_div" : "form_edit_div"}>
                <label>Priority <span>*</span></label><br />
                <Controller
                  name="priority"
                  control={control}
                  rules={{ required: "Priority is required" }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={priorities}
                      styles={DropdownControllers.controlStyles}
                      placeholder="Select Priority"
                    />
                  )}
                />
                <br></br>
                {errors.priority && <span className="required_text">Priority is required</span>}
              </div>

              <div className={errors.category ? "form_error_div" : "form_edit_div"}>
                <label>Category <span>*</span></label><br />
                <Controller
                  name="category"
                  rules={{ required: "Category is required" }}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={categories}
                      styles={DropdownControllers.controlStyles}
                      placeholder="Select Category"
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption);
                        handleCategoryChange(selectedOption);
                      }}
                    />
                  )}
                />
                <br></br>
                {errors.category && <span className="required_text">Category is required</span>}
              </div>

            </div>
            <div className="form_col_edit">
              <div className={errors.assigned_to ? "form_error_div" : "form_edit_div"}>
                <label>Assigned to <span>*</span></label><br />
                <Controller
                  name="assigned_to"
                  rules={{ required: "Assigned to is required" }}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={employeeWorkLoad?.map(item => ({
                        id: item?.EmployeeID,
                        value: assignedToPersonMap[item?.EmployeeID] + ` (${item?.workdays})`,
                        label: assignedToPersonMap[item?.EmployeeID] + ` (${item?.workdays})`,
                        // type: item?.TYPE,
                        icon: <PersonIcon />,
                        email: item?.EMAIL,
                      }))}
                      styles={DropdownControllers.controlStyles}
                      placeholder="Select Assignee"
                      components={{ Option: CustomOption }}
                    />
                  )}
                />
                <br></br>
                {errors.assigned_to && <span className="required_text">Assigned to is required</span>}
              </div>

              {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && <div className={errors.isSample ? "form_error_div" : "form_edit_div"}>
                <label>Is Sample Available? <span>*</span></label>
                <Controller
                  name="isSample"
                  rules={{ required: "This is required" }}
                  control={control}
                  render={({ field }) => (
                    <Select {...field} options={isSampleAvailable} styles={DropdownControllers.controlStyles} placeholder="Select Category" />
                  )}
                />
                <br></br>
                {errors.isSample && <span className="required_text">This is required</span>}
              </div>}
            </div>

            <div className="form_col_edit">
              {isSampleValue?.value === "Yes" && <div className={errors.getSample ? "form_error_div" : "form_edit_div"}>
                <label>Can we get a sample? <span>*</span></label>
                <Controller
                  name="getSample"
                  rules={{ required: "This is required" }}
                  control={control}
                  render={({ field }) => (
                    <Select {...field} options={getAvailableSample} styles={DropdownControllers.controlStyles} placeholder="Select Category" />
                  )}
                />
                <br></br>
                {errors.getSample && <span className="required_text">This is required</span>}
              </div>}

              {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && <div className={errors.isProcess ? "form_error_div" : "form_edit_div"}>
                <label>Do we need to see their process? <span>*</span></label>
                <Controller
                  name="isProcess"
                  rules={{ required: "This is required" }}
                  control={control}
                  render={({ field }) => (
                    <Select {...field} options={seeProcess} styles={DropdownControllers.controlStyles} placeholder="Select Category" />
                  )}
                />
                <br></br>
                {errors.isProcess && <span className="required_text">This is required</span>}
              </div>}
            </div>
            {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && <div className={errors.priority ? "form_error_div" : "form_div"}>
              <label>Process cable is used in <span>*</span></label><br></br>
              <div className={errors.processApplication ? "form_error_div" : "form_div"}>
                <Controller
                  name="processApplication"
                  control={control}
                  render={({ field }) => (
                    <MultiSelectDropdown
                      field={field}
                      rules={{ required: "This is required" }}
                      options={processCableList}
                      defaultValue={ticketData?.process_cable_used_in?.split(", ")?.map(value => ({
                        label: value,
                        value: value
                      }))}
                      displayValue="label"
                    />
                  )}
                />
                <br></br>
                {errors.processApplication && <span className="required_text">This is required</span>}        </div>
            </div>}

            {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && <div className={errors.pullForceNeed ? "form_error_div" : "form_div"}>
              <label>Cable needs specific pull force to remove jacket? <span>*</span></label>
              <Controller
                name="pullForceNeed"
                rules={{ required: "This is required" }}
                defaultValue={{
                  "label": "No",
                  "value": "No"
                }}
                control={control}
                render={({ field }) => (
                  <Select {...field} options={pullForceNeeded} styles={DropdownControllers.controlStyles} placeholder="Select Category" />
                )}
              />
            </div>}

            {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && pullForceNeed?.value == "Yes" && <div className={errors.newColumnPullForce ? "form_error_div" : "form_div"}>
              <label>How much Force? <span>*</span></label><br></br>
              <input
                name="newColumnPullForce"
                type="number"
                className="input_field_edit"
                {...register("newColumnPullForce", { required: true })}
              />
              <span className="number_input_unit">PSI</span>
              <br></br>
              {errors.newColumnPullForce && <span className="required_text">This is required</span>}
            </div>}

            <div className={"form_div"}>
              <label>Special Testing Required?</label>
              <Controller
                name="testingRequired"
                defaultValue={{
                  "label": "No",
                  "value": "No"
                }}
                control={control}
                render={({ field }) => (
                  <Select {...field} options={pullForceNeeded} styles={DropdownControllers.controlStyles} placeholder="Select Category" />
                )}
              />
              <br></br>
            </div>

            {testingRequired?.value === "Yes" && <div className={"form_div"}>
              <label>Please mention the Testing Procedure? </label><br></br>
              <input
                type="text"
                {...register("testingProcedure")}
                className="edit_title"
                placeholder="Enter Testing Procedure"
                defaultValue={ticketData?.SpecialTestingText}
              />
              <br></br>
            </div>}

            {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && <div className={errors.automatedHandling ? "form_error_div" : "form_div"}>
              <label>Cable used in automated handling or stripping process? <span>*</span></label>
              <Controller
                name="automatedHandling"
                control={control}
                rules={{ required: "This is required" }}
                render={({ field }) => (
                  <Select {...field} options={automatedHandlings} styles={DropdownControllers.controlStyles} placeholder="Select Category" />
                )}
              />
              <br></br>
              {errors.automatedHandling && <span className="required_text">This is required</span>}
            </div>}

            {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && automatedHandling?.value == "Yes" && <div className={errors.newColumnAutomated ? "form_error_div" : "form_div"}>
              <label>Automated Handling Details<span>*</span></label>
              <textarea
                name="newColumnAutomated"
                type="textarea"
                {...register("newColumnAutomated", { required: true })}
              />
              <br></br>
              {errors.newColumnAutomated && <span className="required_text">This is required</span>}
            </div>}

            {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && <div className={errors.cableContactPoints ? "form_error_div" : "form_div"}>
              <label>Cable Contact points with external elements <span>*</span></label><br></br>
              <div className={errors.cableContactPoints ? "form_error_div" : "form_div"}>
                <Controller
                  name="cableContactPoints"
                  control={control}
                  render={({ field }) => (
                    <MultiSelectDropdown
                      field={field}
                      defaultValue={ticketData?.contact_points_with_elements?.split(", ")?.map((item) => { return { label: item, value: item } })}
                      options={cableContactPointList}
                      rules={{ required: "This is required" }}
                      displayValue="label"
                    />
                  )}
                />
                <br></br>
                {errors.cableContactPoints && <span className="required_text">This is required</span>}        </div>
            </div>}

            {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && <div className={errors.sunLightRes ? "form_error_div" : "form_div"}>
              <label>Sun light resistant plastic? <span>*</span></label>
              <Controller
                name="sunLightRes"
                control={control}
                rules={{ required: "This is required" }}
                render={({ field }) => (
                  <Select {...field} options={sunLightResistancePlastic} styles={DropdownControllers.controlStyles} placeholder="Select Category" />
                )}
              />
              <br></br>
              {errors.sunLightRes && <span className="required_text">This is required</span>}
            </div>}
            {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && <div className={errors.gammaRaysSterilize ? "form_error_div" : "form_div"}>
              <label>Gamma Ray sterilize? <span>*</span></label>
              <Controller
                name="gammaRaysSterilize"
                rules={{ required: "This is required" }}
                control={control}
                render={({ field }) => (
                  <Select {...field} options={gammaRays} styles={DropdownControllers.controlStyles} placeholder="Select Category" />
                )}
              />
              <br></br>
              {errors.gammaRaysSterilize && <span className="required_text">This is required</span>}
            </div>}

            {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && <div className={errors.ulListingRequired ? "form_error_div" : "form_div"}>
              <label>UL Listing required? <span>*</span></label>
              <Controller
                name="ulListingRequired"
                control={control}
                rules={{ required: "This is required" }}
                render={({ field }) => (
                  <MultiSelectDropdown
                    field={field}
                    options={UlListing}
                    defaultValue={ticketData?.ul_listing_required?.map(value => ({
                      id: value,
                      label: UlListingMapping[value],
                      value: UlListingMapping[value],
                    }))} // Set the default value here
                    onChange={(selected) => {
                      field.onChange(selected); // Update the form state
                    }}
                    displayValue="label"
                  />
                )}
              />
              <br></br>
              {errors.ulListingRequired && <span className="required_text">This is required</span>}
            </div>}


            <hr className="field_seperator" />
            <div className={errors.ticketStatus ? "form_error_div" : "form_div"}>
              <label>Ticket Status <span>*</span></label><br />
              <Controller
                name="ticketStatus"
                control={control}
                rules={{ required: "This is required" }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={ticketStatus}
                    styles={DropdownControllers.controlStyles}
                    placeholder="Ticket Status"
                  />
                )}
              />
              <br></br>
              {errors.ticketStatus && <span className="required_text">This is required</span>}
            </div>

            <div className={errors.description ? "form_error_div" : "form_div"}>
              <label>Description <span>*</span></label><br />
              <Controller
                name="description"
                rules={{ required: "This is required" }}
                control={control}
                render={({ field }) => (
                  <RichText value={field.value} onChange={field.onChange}
                  />
                )}
              />
              <br></br>
              {errors.description && <span className="required_text">This is required</span>}
            </div>

            <div className="form_div">
              <label>Customer Name <span>*</span></label><br />
              <Controller
                name="customerName"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={customerNameList}
                    defaultValue={ticketData?.CustomerID && customerNameMap && { id: ticketData?.CustomerID, label: customerNameMap[ticketData?.CustomerID], value: customerNameMap[ticketData?.CustomerID] }}
                    styles={DropdownControllers.controlStyles}
                    placeholder="Select Customer Name"
                  />
                )}
              />
            </div>
            <div className="form_sub_div">
              <div className={keywordsValue?.length == 0 ? "form_error_div" : "form_div"}>
                <label>Enter Assigned Part Numbers (Enter a value and press enter to select) <span>*</span></label>
                <Controller
                  name="assignedPartNumber"
                  control={control}
                  render={({ field }) => (
                    <CreatableSelect
                      {...field}
                      inputValue={inputValue}
                      styles={DropdownControllers.multiControl}
                      isClearable
                      isMulti
                      menuIsOpen={false}
                      onChange={(selectedOptions) => {
                        field.onChange(selectedOptions);
                        setKeywordsValue(selectedOptions); // Optional: Your custom handler
                      }}
                      onInputChange={(newValue) => {
                        if (isMobile) {
                          if (newValue != "") {
                            setInputValue(newValue);
                          } else if (inputValue.length == 1 && newValue == "") {
                            setInputValue(newValue);
                          }
                        } else {
                          setInputValue(newValue);
                        }
                      }}
                      onKeyDown={handleKeyDown}
                      placeholder="Enter Assigned Part Numbers"
                      value={keywordsValue}

                    />
                  )}
                />
                <br></br>
                {keywordsValue?.length == 0 && <span className="required_text">This is required</span>}
              </div>
              <span className="required_text">{validationMsg}</span>
              <div className="number_items">
                <div className="number_item">
                  <div className={"form_div"}>
                    <label>Quote Part Required</label><br />
                    <input
                      name="quotePartRequired"
                      className="edit_title"
                      type="number"
                      {...register("quotePartRequired")}
                    />
                    <br></br>
                  </div>

                  <div className={"form_div"}>
                    <label>Order Part Required</label><br />
                    <input
                      name="orderPartRequired"
                      type="number"
                      className="edit_title"
                      {...register("orderPartRequired")}
                    />
                    <br></br>
                  </div>

                </div>

                <div className="number_item">
                  <div className={"form_div"}>
                    <label>Word Specs Required</label><br />
                    <input
                      name="wordSpecsRequired"
                      type="number"
                      className="edit_title"
                      {...register("wordSpecsRequired")}
                    />
                  </div>

                  <div className={"form_div"}>
                    <label>CAD Drawings Required</label><br />
                    <input
                      name="cadDrawingsRequired"
                      type="number"
                      className="edit_title"
                      {...register("cadDrawingsRequired")}
                    />
                    <br></br>
                  </div>
                </div>
              </div>
              {/* <div className={"form_div"}>
            <label>Late Reason</label><br />
            <textarea
              name="late_reason"
              {...register("late_reason")}
              placeholder="Enter Your Description"
              className="small_desc"
            />
            <br></br>
          </div> */}
              {/* <div className={errors.notes ? "form_error_div" : "form_div"}>
          <label>Notes <span>*</span></label><br />
          <textarea
            name="notes"
            {...register("notes", { required: true })}
            placeholder="Enter Your Description"
            className="small_desc"
          />
          <br></br>
          {errors.notes && <span className="required_text">This is required</span>}
        </div> */}
            </div>

            <div className="form_col_edit">
              <div className={"form_edit_div"}>
                <label>Reason for No Quote</label><br />
                <Controller
                  name="noquote_reason"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={reasonForNoQuote}
                      styles={DropdownControllers.controlStyles}
                      placeholder="Select Reason"
                    />
                  )}
                />
                <br></br>
              </div>

              <div className={"form_edit_div"}>
                <label>Mistake Code</label><br />
                <Controller
                  name="mistake_code"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={mistakeCodes}
                      styles={DropdownControllers.controlStyles}
                      placeholder="Select Mistake Code"
                    />
                  )}
                />
                <br></br>
              </div>
            </div>

            <div className="form_div">
              <label>Notes for Sales/PD</label><br />
              <textarea
                name="notes_sales"
                {...register("notes_sales")}
                placeholder="Enter Your Notes"
                className="small_desc"
              />
              <br></br>
            </div>
            <div className={"form_div"}>
              <div className="price_container">
                <label>Price</label><br />
                <div className="input_with_icon_price">
                  <input
                    name="price"
                    type="number"
                    {...register("price")}
                    className="price_input"
                  />
                  <span className="icon_container">
                    <AttachMoneyOutlinedIcon sx={{ backgroundColor: "#373737", color: "#ef6e35", left: "0", position: "absolute", height: "25px", width: "25px", borderRadius: "5px", marginLeft: "9px", marginTop: "3px" }} />
                  </span>
                </div>
              </div>
            </div>
            <div className={"form_div"}>
              <label>Footage</label><br />
              <input
                name="footage"
                type="text"
                className="input_field_edit"
                {...register("footage")}
                placeholder="Enter Footage"
                onChange={handleFootageChange}
              />
              <span className="number_input">ft</span>
            </div>

            <div className={"form_div"}>
              <label>Emails to Notify</label><br />
              <Controller
                name="emailsToNotify"
                control={control}
                render={({ field }) => (
                  <MultiSelectDropdown
                    field={field}
                    options={groupEmployeData}
                    defaultValue={ticketData?.emailsToNotify?.map(value => ({
                      id: value?.GroupID ? value?.GroupID : value?.EmployeeID,
                      label: value?.GroupID ? assignedToGroupMap[value?.GroupID] : assignedToPersonMap[value?.EmployeeID],
                      value: value?.GroupID ? assignedToGroupMap[value?.GroupID] : assignedToPersonMap[value?.EmployeeID],
                      icon: value?.GroupID ? <GroupIcon /> : <PersonIcon />
                    }))} // Set the default value here
                    onChange={(selected) => {
                      field.onChange(selected); // Update the form state
                    }}
                    displayValue="label"
                  />
                )}
              />
            </div>

            <div className="form_div">
              <label>Attachments</label><br />
              <input
                type="file"
                id="attachments"
                name="attachments"
                multiple
                {...register("attachments", {
                  onChange: handleUploadImage
                })}
                style={{ display: "none" }} // Hides the default input
              />
              <label
                htmlFor="attachments"
                className="custom_file_upload"
              >
                <div>
                  <CloudDoneOutlinedIcon />
                  <p className="upload_text">Choose a file or drag & drop <br></br>it here (50MB)</p>
                  <div className="browse_btn">
                    <span className="browse_btn_text">Browse File</span>
                  </div>
                </div>
              </label>
            </div>
            {
              attachmentLoading &&
              <>
                <CircularProgress sx={{ marginTop: "30px", marginLeft: "20%" }} />
              </>
            }
            {
              attachmentsList?.map((item) => {
                return (
                  <AttachedFile filename={item} ticketId={ticketData?.TicketID} setSelectedFile={setAttachmentsList} attachmentsList={attachmentsList} />
                );
              })
            }

            <hr className="field_seperator" />

            <div className="desc_container">
              <div className="btn_div">
                <button
                  id="cancel_btn"
                  type="button"
                  onClick={() => { reset(initialValues); dispatch(setOpenEditModal(false)); navigate("/"); }}
                >
                  Cancel
                </button>
                <button
                  id="edit_ticket"
                  type="submit"
                >
                  Save Changes
                </button>
              </div>

            </div>
            <div className="desc_div">

              {lineageData?.ancestor && <span className="descendants_text">Ancestor: </span>}
              {lineageData?.ancestor &&
                <table className="descendants_table">
                  <thead>
                    <tr>
                      <th>Ticket ID</th>
                      <th>Title</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td onClick={() => { dispatch(setOpenEditModal(false)); navigate(`/edit-ticket/?ticketId=${lineageData?.ancestor}`) }}>#{lineageData?.ancestor}</td>
                      <td onClick={() => { dispatch(setOpenEditModal(false)); navigate(`/edit-ticket/?ticketId=${lineageData?.ancestor}`) }}>{ticketTitleMap[lineageData?.ancestor]}</td>
                    </tr>
                  </tbody>
                </table>
              }
              {lineageData?.descendants?.length > 0 && <span className="descendants_text">Descendants: </span>}
              {lineageData?.descendants?.length > 0 &&
                <table className="descendants_table">
                  <thead>
                    <tr>
                      <th>Ticket ID</th>
                      <th>Title</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lineageData?.descendants?.map((item, index) => (
                      <tr key={index}>
                        <td onClick={() => { dispatch(setOpenEditModal(false)); navigate(`/edit-ticket/?ticketId=${item}`) }}>#{item}</td>
                        <td onClick={() => { dispatch(setOpenEditModal(false)); navigate(`/edit-ticket/?ticketId=${item}`) }}>{ticketTitleMap[item]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              }
            </div>
          </div>}
        {
          (isBackdropLoading || isLoading) && <CircularBackdrop />
        }
      </div>
      <div className="comments" id="comments_mobile">
        {isMobile && <Comments />}
      </div>
    </form>
  );
};

export default EditTicketForm;
