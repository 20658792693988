import ActionCellRenderer from "../dashboard/ActionCellRenderer";
import CustomHeader from "../dashboard/CustomHeader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DnsIcon from '@mui/icons-material/Dns';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import "./index.css";
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import ShowCellRenderer from "./ShowCellRenderer";
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ActionSkillRenderer from "../dashboard/ActionSkillRenderer";
import OemText from "../dashboard/OemText";
const isMobile = window.innerWidth <= 768;

const employeeSkillsColumns = [
    {
        field: "EmployeeID",
        headerComponent: CustomHeader,
        headerComponentParams: { icon: <InfoOutlinedIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Employee ID" },
        filter: "agSetColumnFilter",
        minWidth: isMobile ? 40 : 250,
        rowSpan: (params) => {
            // Merge cells for EmployeeName
            return params.data.TotalSkills;
        },
        cellRenderer: ShowCellRenderer,
        cellClassRules: {
            "cell-span": "value !== undefined", // Apply styling to spanned cells
        },
        filterParams: {
            defaultToNothingSelected: true,
            // default: "Urgent",  
        },
        cellStyle: (params) => {
            return {
                backgroundColor: "#222222",
                color: "white",
                cursor: "pointer",
                padding: "0px"
            };
        },
        // pinned: true,
        // hide: isMobile,
        cellDataType: false,
    },
    {
        field: "work_time",
        headerComponent: CustomHeader,
        headerComponentParams: { icon: <AccessTimeIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Work Time" },
        filter: isMobile ? "" : "agSetColumnFilter",
        minWidth: isMobile ? 40 : "250",
        filterParams: {
            defaultToNothingSelected: true,
            // default: "Urgent",  
        },
        rowSpan: (params) => {
            return params.data.TotalSkills;
        },
        // cellEditorParams: (params) => {
        //     return {
        //         backgroundColor: params?.api?.cellStartedEdit ? "#222222" : "",
        //     };
        // },
        cellRenderer: ShowCellRenderer,
        cellStyle: (params) => {
            return {
                backgroundColor: "#222222",
                color: "white",
                cursor: "pointer",
                padding: "0px",
                zIndex: 0,
            };
        },
        cellClassRules: {
            "cell-span": "value !== undefined", // Apply styling to spanned cells
        },
        cellEditorParams: {
            // Make the editor bigger by increasing the width and height
            maxLength: 500, // Optional: Set a max length to allow larger input
        },
        editable: (params) => {
            return params?.data?.RowIndex == 0 ? true : false;
        },
        // hide: isMobile,
        cellDataType: false,
    },
    // {
    //     field: "skill_id",
    //     headerComponent: CustomHeader,
    //     headerComponentParams: { icon: <AutoFixHighOutlinedIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Skill ID" },
    //     filter: isMobile ? "" : "agSetColumnFilter",
    //     // minWidth: isMobile ? 40 : 250,
    //     filterParams: {
    //         defaultToNothingSelected: true,
    //         // default: "Urgent",  
    //     },
    //     cellStyle: (params) => {
    //         return {
    //             backgroundColor: "#222222",
    //             color: "white",
    //             cursor: "pointer"
    //         };
    //     },
    //     hide: isMobile,
    // },
    {
        field: "category_id",
        cellEditor: 'agSelectCellEditor',
        headerComponent: CustomHeader,
        headerComponentParams: { icon: <ControlPointDuplicateIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Category ID" },
        filter: isMobile ? "" : "agSetColumnFilter",
        minWidth: isMobile ? 40 : "250",
        filterParams: {
            defaultToNothingSelected: true,
        },
        cellEditorParams: (params) => {
            const dropdownValues = params?.data?.categories?.map(item => `${item.label} (${item.id})`) || [];

            return {
                values: dropdownValues,
                valueListMaxHeight: 220,
            };
        },
        cellStyle: (params) => {
            return {
                backgroundColor: "#222222",
                color: "white",
                cursor: "pointer"
            };
        },
        // editable: true,
        // hide: isMobile,
    },
    {
        field: "job_type",
        headerComponent: CustomHeader,
        headerComponentParams: { icon: <WorkspacesIcon sx={{ height: "18px", width: "18px" }} />, displayName: "Job Type" },
        filter: isMobile ? "" : "agSetColumnFilter",
        minWidth: isMobile ? 40 : 450,
        filterParams: {
            defaultToNothingSelected: true,
            // default: "Urgent",  
        },
        cellRenderer: (params) => {
            const value = params?.value;
            return (
                <>
                    {value?.map((item) => (
                        <OemText key={item?.id} value={item.value} />
                    ))}
                </>
            );
        },
        cellStyle: (params) => {
            return {
                backgroundColor: "#222222",
                color: "white",
                cursor: "pointer"
            };
        },
        // editable: true,
        // hide: isMobile,
    },
    {
        headerName: 'Delete',
        field: 'actions',
        cellRenderer: ActionSkillRenderer,
        // flex: 1, 
        minWidth: isMobile ? 40 : "400",
        resizable: true,
        sortable: false,
        filter: false,
        cellStyle: (params) => {
            return {
                backgroundColor: "#222222",
                color: "white",
            };
        },
        // pinned: true
    },
]
export default employeeSkillsColumns;