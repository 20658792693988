import "./index.css";
import Modal from "@mui/material/Modal";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { deleteExistingEmployeeSkills, setDeleteEmployeeSkills } from "../../actions/ticketActions";
const ActionSkillRenderer = (props) => {
    const dispatch = useDispatch();
    // const [editHover, setEditHover] = useState(false);
    const [isAskDelete, setIsAskDelete] = useState(false);
    const [deleteHover, setDeleteHover] = useState(false);
    const [dataToBeDeleted, setDataToBeDeleted] = useState("");
    const { deleteEmployeeSkills } = useSelector(state => state.tickets);

    const handleDelete = () => {
        setIsAskDelete(true);
        setDataToBeDeleted(props?.data?.job_type);
    };

    const handleAskDelete = () => {
        setIsAskDelete(false);
    }
    const handleConfirmDelete = () => {
        for (let i = 0; i < dataToBeDeleted?.length; i++) {
            dispatch(deleteExistingEmployeeSkills(dataToBeDeleted[i]?.skillId));
        }
    }
    useEffect(() => {
        if (deleteEmployeeSkills?.status === "success") {
            handleAskDelete();
            dispatch(setDeleteEmployeeSkills({}));
        }
    }, [deleteEmployeeSkills])
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'left', gap: '10px' }}>
                {/* <div onClick={handleEdit} className="edit_row_icon">
                <EditRoundedIcon sx={{ backgroundColor:editHover ? "#2b303a" : "", cursor:"pointer", padding:"5px", borderRadius:"10px" }} onMouseEnter={() => setEditHover(true)}
                    onMouseLeave={() => setEditHover(false)} />
            </div> */}
                <div onClick={handleDelete} className="edit_row_icon">
                    <DeleteRoundedIcon sx={{ color: deleteHover ? "#ef6e35" : "", backgroundColor: deleteHover ? "#2b303a" : "", cursor: "pointer", padding: "5px", borderRadius: "10px" }} onMouseEnter={() => setDeleteHover(true)}
                        onMouseLeave={() => setDeleteHover(false)} />
                </div>
            </div>
            <Modal
                open={isAskDelete}
                onClose={handleAskDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflowY: "auto",
                    height: "auto",
                    marginTop: "20px",
                    marginBottom: "20px",
                    overflow: "hidden",
                    // paddingTop:"40px"
                    // width:"10px"
                    // overflow: "auto", 
                }}
            >
                <div className="outer_form">
                    <div className="inner_form_category">
                        <p className="modal_question">Are you sure, you want to delete?</p>
                        <div className="btn_div">
                            <button
                                id="cancel_btn"
                                type="button"
                                onClick={handleAskDelete}
                            >
                                No
                            </button>
                            <button
                                id="edit_ticket"
                                onClick={handleConfirmDelete}
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ActionSkillRenderer;