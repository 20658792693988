import { useForm, Controller} from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { createEmployeeGroup, createNewGroup, setAddEmployeeGroups, setCreateGroupData } from "../../actions/ticketActions";
import { useEffect } from "react";
import Select, { components } from "react-select";
import PersonIcon from '@mui/icons-material/Person';
import DropdownControllers from "../dashboard/DropdownControllers";

const AddEmployeeForm = ({ handleCategoryClose }) => {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();
    const dispatch = useDispatch();
    const { addEmployeeGroups, groupEmployeData, assignedToPersonMap, groupTableData } = useSelector(state => state.tickets);
    const onSubmit = (data) => {
        dispatch(createEmployeeGroup({
            GroupID: data?.group?.id,
            EmployeeID: data?.employee?.id,
        }));
    }
    useEffect(() => {
        if (addEmployeeGroups?.status === "success") {
            handleCategoryClose();
            dispatch(setAddEmployeeGroups({}));
        }
    }, [addEmployeeGroups]);
    const CustomOption = (props) => {
        return (
            <components.Option {...props}>
                {/* Render an icon and the option label */}
                <div style={{ display: "flex", alignItems: "center" }}>
                    {props.data.icon && <span style={{ marginRight: "8px" }}>{props.data.icon}</span>}
                    {props.data.label}
                </div>
            </components.Option>
        );
    };

    return (
        <>
            <div className="outer_form">
                <div className="inner_form_category">
                    <p className="form_header_label">Add Employee</p>
                    <form onSubmit={handleSubmit(onSubmit)} className="form_main">
                        <div className="form_div">
                            <div className={errors.employee ? "form_error_div" : "form_edit_div"}>
                                <label>Select Employee <span>*</span></label><br />
                                <Controller
                                    name="employee"
                                    rules={{ required: "Employee is required" }}
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={groupEmployeData.filter(item => item.type === "employee")?.map(item => ({
                                                id: item?.id,
                                                value: assignedToPersonMap[item?.id],
                                                label: assignedToPersonMap[item?.id],
                                                type: item?.type,
                                                icon: <PersonIcon />,
                                                email: item?.email,
                                            }))}
                                            styles={DropdownControllers.controlStylesDropdown}
                                            placeholder="Select Assignee"
                                            components={{ Option: CustomOption }}
                                        />
                                    )}
                                />
                                <br></br>
                                {errors.employee && <span className="required_text">Employee is required</span>}
                            </div>
                        </div>
                            <div className={errors.group ? "form_error_div" : "form_edit_div"}>
                                <label>Select Group <span>*</span></label><br />
                                <Controller
                                    name="group"
                                    rules={{ required: "Group is required" }}
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={groupTableData?.data?.map(item => ({
                                                id: item?.GroupID,
                                                value: item?.GroupName,
                                                label: item?.GroupName,
                                                icon: <PersonIcon />,
                                                email: item?.email,
                                            }))}
                                            styles={DropdownControllers.controlStylesDropdown}
                                            placeholder="Select Assignee"
                                            components={{ Option: CustomOption }}
                                        />
                                    )}
                                />
                                <br></br>
                                {errors.group && <span className="required_text">Group is required</span>}
                            </div>
                        <div className="btn_div">
                            <button
                                id="cancel_btn"
                                type="button"
                                onClick={handleCategoryClose}
                            >
                                Cancel
                            </button>
                            <button
                                id="edit_ticket"
                                type="submit"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default AddEmployeeForm;