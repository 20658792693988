import { useDispatch, useSelector } from "react-redux";
import Comments from "./Comments";
import EditTicketForm from "./EditTicketForm";
import "./index.css";
import { markNotificationStatus, setOpenEditModal } from "../../actions/ticketActions";
import { useNavigate, useSearchParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEffect } from "react";
const isMobile = window.innerWidth <= 768;

const EditTicket = () => {
    const {
        notificationStatusMap,
    } = useSelector(state => state.tickets);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const ticketId = searchParams.get("ticketId");
    useEffect(() => {
        if (notificationStatusMap[ticketId] === false) {
            dispatch(markNotificationStatus(ticketId));
        }
    }, [ticketId]);

    return (
        <>
            <div className="outer_form_edit">
                <div className="back_btn_div">
                    <div className="back_btn" onClick={() => { navigate("/"); dispatch(setOpenEditModal(false)) }}>
                        <ArrowBackIcon sx={{ color: "white" }} />
                    </div>
                </div>
                <div className="inner_edit">
                    <EditTicketForm />
                </div>
                <div className="comments" id="comments_desktop">
                    {!isMobile && <Comments />}
                </div>
            </div>
        </>
    );
};
export default EditTicket;