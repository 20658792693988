import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { createNewGroup, setCreateGroupData } from "../../actions/ticketActions";
import { useEffect } from "react";

const AddGroupForm = ({ handleCategoryClose }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const dispatch = useDispatch();
    const { createGroupData } = useSelector(state => state.tickets);
    const onSubmit = (data) => {
        dispatch(createNewGroup({
            GroupName: data?.group_name,
        }));
    }
    useEffect(() => {
        if (createGroupData?.status === "success") {
            handleCategoryClose();
            dispatch(setCreateGroupData({}));
        }
    }, [createGroupData]);

    return (
        <>
            <div className="outer_form">
                <div className="inner_form_category">
                    <p className="form_header_label">Add Group</p>
                    <form onSubmit={handleSubmit(onSubmit)} className="form_main">
                        <div className={errors.group_name ? "form_error_div" : "form_div"}>
                            <label>Group Name <span>*</span></label><br></br><br></br>
                            <input
                                type="text"
                                {...register("group_name", { required: true })}
                                placeholder="Enter Group Name"
                                className="input_field_category"
                            />
                            <br></br>
                            {errors.group_name && <span className="required_text">Group Name is required</span>}
                        </div>
                        <div className="btn_div">
                            <button
                                id="cancel_btn"
                                type="button"
                                onClick={handleCategoryClose}
                            >
                                Cancel
                            </button>
                            <button
                                id="edit_ticket"
                                type="submit"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default AddGroupForm;